//import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import "../../assets/styles/pages/videos.scss"

//import components
import InfiniteScroll from "react-infinite-scroller";
import PageHeader from "../components/dataDisplay/PageHeader";
import VideoCard from "../components/VideoCard";
import MetaTags from "../components/MetaTags";

//import utils
import {GetAllNewsWeb} from "../redux/actions";
import {LIMIT_GET_NEWS, NEWS_MEDIA_TYPES} from "../../admin/constants/constants";
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import { createUrl } from "../utils/createUrl";
import { history } from "../config/history";

class Videos extends Component {
    constructor() {
        super();

        this.state = {
            currentPlayingVideo: null,
            loading: false
        };

        this.getNews = this.getNews.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.toggleCurrentPlayingVideo = this.toggleCurrentPlayingVideo.bind(this);
    }

    async componentDidMount() {
        const categoryId = this.props.selectedCategoryId;
        await this.setState({
            loading: true
        });
        await this.props.GetAllNewsWeb({
            categoryId: categoryId,
            type: NEWS_MEDIA_TYPES.VIDEO
        });

        this.setState({
            loading: false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage, videosList} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            videosList && this.props.GetAllNewsWeb({
                categoryId: videosList.categoryId,
                type: NEWS_MEDIA_TYPES.VIDEO
            });
            history.push(createUrl('videos'))
        }
    }

    getNews(id) {
        this.props.GetAllNewsWeb({
            categoryId: id,
            type: NEWS_MEDIA_TYPES.VIDEO
        });
    }


    loadMore() {
        const {videosList} = this.props;

        if (videosList.hasMore && !this.state.loading) {
            const offset = videosList.itemsList && videosList.itemsList.length;
            this.props.GetAllNewsWeb({
                reset: false,
                categoryId: videosList.categoryId,
                offset,
                limit: LIMIT_GET_NEWS,
                type: NEWS_MEDIA_TYPES.VIDEO
            });
        }
    }

    toggleCurrentPlayingVideo(id = null) {
        this.setState({
            currentPlayingVideo: id
        })
    }

    render() {
        const {videosList, translation} = this.props;
        const pageHeader = {
            title: (translation && translation['videos-page-title'])||'Տեսադարան',
            slider: true,
            filter: false,
        };
        return <div className="video-page">
            <MetaTags title={translation && translation['videos-page-title']}/>
            <PageHeader pageHeader={pageHeader} getNews={this.getNews}/>

            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={this.loadMore}
                className={'video-content'}
                hasMore={videosList && videosList.hasMore}>

                {videosList && videosList.itemsList && videosList.itemsList.map(item => {
                    return <VideoCard
                        card={item}
                        key={item.id}
                        currentPlayingVideo={this.state.currentPlayingVideo}
                        toggleCurrentPlayingVideo={this.toggleCurrentPlayingVideo}
                    />
                })}

            </InfiniteScroll>
        </div>
    }
}

const mapDispatchToProps = {
    GetAllNewsWeb
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);

import {createSelector} from "reselect";
import {categorySelectorKeys} from "./selectorKeys";

const category_S = state => state.categories;

const creatorFn = selectorKey => {
    return createSelector(category_S, data => data[selectorKey])
};

export const categorySelectors = state => {
    const sData = {};

    categorySelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
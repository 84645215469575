import axios from "axios";
import {store} from "../../../admin/redux/store";

export const request = (url, method = "GET", languageFlag = false, data=null) => {

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };
    let language = (store.getState() && store.getState().web && store.getState().web.selectedLanguage
        &&store.getState().web.selectedLanguage.code) || 'hy';
    if (languageFlag) {
        headers["Accept-Language"] = language
    }
    const requestData = {
        url,
        method,
        headers,
    };
    if (data) {
        requestData.data = data
    }
    return axios.request(requestData)
};


/*
axios.interceptors.response.use(response => response, (error) => {
        if (error && error.response && error.response.status === 401) {
            store.dispatch({
                type: authConsts.LOG_OUT
            });
            history.push("/admin/login")
        }
        return Promise.reject(error)
    }
);*/

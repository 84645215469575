// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import components

// Import utils
import { mapStateToProps } from "../../admin/redux/mapStateToProps";
import SimpleSlider from "../components/homePage/Slider";
import About from "../components/homePage/About";
import News from "../components/homePage/News";
import VideoGallery from "../components/homePage/VideoGallery";
import { history } from "../config/history";
import { createUrl } from "../utils/createUrl";

//import Questions from "../components/homePage/Questions";

class Homepage extends Component {
    componentDidUpdate(prevProps, prevState, snapshot){
        const { selectedLanguage } = this.props;
        if ( selectedLanguage.code !== prevProps.selectedLanguage.code ) {
            history.push(createUrl('/'))
        }
    }

    render() {
        return <div className="homepage">
            <SimpleSlider/>
            <div className="content-wrapper">
                <About/>
                <News/>
                <VideoGallery/>
                {/*<Questions/>*/}
            </div>
        </div>
    }
}

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

import { consts } from "../constants";
import { StaticTextFields } from "../../constants/StaticTexsts";
import { LANGUAGES, LANGUAGES_OBJ } from "../../constants/constants";

// console.log(window.location)
const urlLg = window.location.pathname.split('/')?.[1];
const currentLanguage = LANGUAGES.includes(urlLg) ? LANGUAGES_OBJ[urlLg] : LANGUAGES_OBJ.hy;
// console.log(currentLanguage);
const initialState = {
    metaTags: {
        title: '',
        description: ''
    },
    languages: [],
    selectedLanguage: currentLanguage,
    translationsData: StaticTextFields,
    translation: StaticTextFields.hy,

    menuList: [],
    rightMenu: [],

    slider: [],

    categories: [],
    selectedCategoryId: '',

    newsList: {
        itemsList: [],
        hasMore: false,
        categoryId: null,
        searchText: '',
    },
    searchedData: {
        itemsList: [],
        hasMore: false,
        categoryId: null,
        searchText: '',
        type: '',
    },

    videosList: {
        itemsList: [],
        hasMore: false,
        categoryId: null,
        searchText: '',
    },
    newsDetails: null,
    institutions: [],
    institutionData: null,
    members: [],
    memberData: null,
    singleMember: [],
    chief: {},
    contacts: [],
    customPageData: {},
    vacanciesPageData: {},

};

export default (state = initialState, action) => {

    switch (action.type) {
//translations
        case consts.GET_ALL_LANGUAGE_WEB:
            // console.log('state.selectedLanguage', state.selectedLanguage)
            const mainLanguage = action.payload.find(lg => lg.isMain);

            const stateLgCode = state.selectedLanguage?.code;

            let language = action.payload.find(lg => lg.code === stateLgCode)

            if ( !language ) {
                language = mainLanguage || action.payload[0]
            }
            // console.log('language', language)
            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: language,
            };
            if ( state.translationsData && state.translationsData[language.code] ) {
                newState.translation = state.translationsData[language.code]
            }
            return newState;

        case consts.CHANGE_LANGUAGE:
            // console.log('reducer change language')
            return {
                ...state,
                selectedLanguage: action.payload,
                translation: (state.translationsData && state.translationsData[action.payload.code]) || {}
            };

        case consts.SET_TRANSLATIONS:
            return {
                ...state,
                translationsData: action.payload,
                translation: (action.payload && state.selectedLanguage &&
                    state.selectedLanguage.code && action.payload[state.selectedLanguage.code]) || action.payload.hy
            };


        // Menu
        case consts.GET_MENU_WEB:
            return {
                ...state,
                menuList: action.payload
            };
        case consts.GET_RIGHT_MENU_WEB:
            return {
                ...state,
                rightMenu: action.payload
            };
        case consts.GET_SLIDER_WEB:
            return {
                ...state,
                slider: action.payload
            };
        case consts.GET_CATEGORIES_WEB:
            return {
                ...state,
                categories: action.payload
            };
        case consts.GET_CATEGORY_ID_WEB:
            return {
                ...state,
                selectedCategoryId: action.payload
            };
        //Search reducer
        case consts.SET_SEARCH_DATA:
            const newSearchData = {
                itemsList: action.payload.reset ? action.payload.data : [...state.searchedData.itemsList, ...action.payload.data],
                categoryId: action.payload.categoryId,
                hasMore: action.payload.hasMore,
                searchText: action.payload.searchText,
                type: action.payload.type,
            };
            return {
                ...state,
                searchedData: newSearchData
            };
        case consts.CLEAR_SEARCH_DATA:
            return {
                ...state,
                searchedData: initialState.searchedData
            };
        case consts.GET_ALL_NEWS_WEB:
            const newNewsList = {
                itemsList: action.payload.reset ? action.payload.data : [...state.newsList.itemsList, ...action.payload.data],
                categoryId: action.payload.categoryId,
                hasMore: action.payload.hasMore,
                searchText: action.payload.searchText,
            };
            return {
                ...state,
                newsList: newNewsList
            };
        case consts.GET_ALL_VIDEOS_WEB:
            const newVideosList = {
                itemsList: action.payload.reset ? action.payload.data : [...state.videosList.itemsList, ...action.payload.data],
                categoryId: action.payload.categoryId,
                hasMore: action.payload.hasMore,
                searchText: action.payload.searchText,
            };
            return {
                ...state,
                videosList: newVideosList
            };
        case consts.GET_WEB_NEWS_BY_ID:
            return {
                ...state,
                newsDetails: action.payload
            };
        case consts.CLEAR_NEWS_DETAILS:
            return {
                ...state,
                newsDetails: null,
            };

        case consts.GET_CONTACT_WEB:
            return {
                ...state,
                contacts: action.payload
            };

            //Institutions
        case consts.GET_INSTITUTIONS_WEB:
            return {
                ...state,
                institutions: action.payload
            };

        case consts.GET_INSTITUTION_BY_ID:
            return {
                ...state,
                institutionData: action.payload
            };

        case consts.CLEAR_INSTITUTION_BY_ID:
            return {
                ...state,
                institutionData: null
            };

            //Members
        case consts.GET_MEMBERS_WEB:
            return {
                ...state,
                members: action.payload
            };
        case consts.GET_MEMBER_BY_ID_WEB:
            return {
                ...state,
                memberData: action.payload
            };
        case consts.CLEAR_MEMBER_BY_ID_WEB:
            return {
                ...state,
                memberData: null
            };
        case consts.GET_CHIEF_WEB:
            return {
                ...state,
                chief: action.payload
            };

//custom pages
        case consts.SET_CUSTOM_PAGE:
            return {
                ...state,
                customPageData: action.payload
            };
        case consts.CLEAR_CUSTOM_PAGE:
            return {
                ...state,
                customPageData: {}
            };


        case consts.SET_VACANCIES_DATA:
            return {
                ...state,
                vacanciesPageData: action.payload
            };

//Meta tags

        case consts.SET_META_DATA:
            return {
                ...state,
                metaTags: {
                    title: action.payload.title || "",
                    description: action.payload.description || "",
                }
            };
        case consts.CLEAR_META_DATA:
            return {
                ...state,
                metaTags: initialState.metaTags
            };
        default:
            return state;
    }
}

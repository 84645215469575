
// const ADMIN_API_URL_V1 = "http://173.249.20.192:7000/api/v1";
//
// const FRONT_API_URL_V1 =  "http://173.249.20.192:7001/api/v1";
//
// const HOST_URL_V1 = "http://173.249.20.192:7000";


//====================================== studio-one api ============================================


const ADMIN_API_URL_V1 = (process.env.REACT_APP_ADMIN_API_URL_V1 && process.env.REACT_APP_ADMIN_API_URL_V1 + "/api/v1/") || "https://admin-api.penitentiary.am/api/v1";

const FRONT_API_URL_V1 = (process.env.REACT_APP_FRONT_API_URL_V1 && process.env.REACT_APP_FRONT_API_URL_V1 + "/api/v1/") || "https://front-api.penitentiary.am/api/v1";

const HOST_URL_V1 = (process.env.REACT_APP_HOST_URL_V1 && process.env.REACT_APP_HOST_URL_V1) || "https://static.penitentiary.am";
// const HOST_URL_V1 = (process.env.REACT_APP_HOST_URL_V1 && process.env.REACT_APP_HOST_URL_V1) || "https://admin-api.ced.am";




export const _urlOauth = ADMIN_API_URL_V1 + "/oauth2";
export const _urlStaticPage = ADMIN_API_URL_V1 + "/static-pages";
export const _urlUser = ADMIN_API_URL_V1 + "/users";
export const _urlRole = ADMIN_API_URL_V1 + "/roles";
export const _urlLanguage = ADMIN_API_URL_V1 + "/languages";
export const _urlNews = ADMIN_API_URL_V1 + "/news";
export const _urlCategories = ADMIN_API_URL_V1 + "/categories";
export const _urlMedia = ADMIN_API_URL_V1 + "/files";
export const _urlMenu = ADMIN_API_URL_V1 + "/menu";
export const _urlPages = ADMIN_API_URL_V1 + "/custom-pages";
export const _urlSlider = ADMIN_API_URL_V1 + "/slider";
export const _urlContact = ADMIN_API_URL_V1 + "/contact";
export const _urlMembers = ADMIN_API_URL_V1 + "/members";
export const _urlInstitutions = ADMIN_API_URL_V1 + "/institutions";
export const _urlVacancies = ADMIN_API_URL_V1 + "/vacancies";
export const _urlStatic = ADMIN_API_URL_V1 + "/static-texts";
export {FRONT_API_URL_V1, HOST_URL_V1};


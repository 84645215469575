//import packages
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

//import utils
import {mapStateToProps} from "../../../admin/redux/mapStateToProps";
import {GetAllNewsWeb} from "../../redux/actions";

//import components
import VideoCard from "../VideoCard";

//import styles
import "../../../assets/styles/homePage/VideoGallery.scss"
import {NEWS_MEDIA_TYPES} from "../../../admin/constants/constants";
import { createUrl } from "../../utils/createUrl";

class VideoGallery extends Component {
    constructor() {
        super();

        this.state = {
            currentPlayingVideo: null,
        };

        this.toggleCurrentPlayingVideo = this.toggleCurrentPlayingVideo.bind(this);
    }

    componentDidMount() {
        this.props.GetAllNewsWeb({limit: 3, type: NEWS_MEDIA_TYPES.VIDEO});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetAllNewsWeb({limit: 3, type: NEWS_MEDIA_TYPES.VIDEO});
        }
    }

    toggleCurrentPlayingVideo(id = null) {
        this.setState({
            currentPlayingVideo: id
        })
    }

    render() {
        const {videosList, translation} = this.props;

        return (
            <div className="gallery">
                {
                    videosList && !!videosList.itemsList.length && <div className="gallery-title">
                        <h1 className="title">{translation && translation['home-videos-section-title']}</h1>
                        <Link to={{
                            pathname: createUrl("videos"),
                        }}>
                            <p className="link">{translation && translation['home-videos-link-text']}<img
                                src={require('../../../assets/images/icons/ic_arrow_more.svg')} alt="img"/></p>
                        </Link>
                    </div>
                }
                <div className="content">
                    {

                        videosList && videosList.itemsList && Array.isArray(videosList.itemsList)
                        && videosList.itemsList.slice(0, 3).map((item) => {
                            return <VideoCard
                                card={item}
                                key={item.id}
                                currentPlayingVideo={this.state.currentPlayingVideo}
                                toggleCurrentPlayingVideo={this.toggleCurrentPlayingVideo}
                            />
                        })
                    }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    GetAllNewsWeb,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoGallery);

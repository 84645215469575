import {FRONT_API_URL_V1, HOST_URL_V1} from '../../../admin/redux/api/urls';

export const host = HOST_URL_V1;
export const _urlLanguage = FRONT_API_URL_V1 + "/languages";
export const _urlMenu = FRONT_API_URL_V1 + "/menu";
export const _urlSlider = FRONT_API_URL_V1 + "/slider";
export const _urlCategories = FRONT_API_URL_V1 + "/categories";
export const _urlNews = FRONT_API_URL_V1 + "/news";
export const _urlInstitutions = FRONT_API_URL_V1 + "/institutions";
export const _urlMembers = FRONT_API_URL_V1 + "/members";
export const _urlContact = FRONT_API_URL_V1 + "/contact";
export const _urlCustomPage = FRONT_API_URL_V1 + "/custom-pages";
export const _urlVacancies = FRONT_API_URL_V1 + "/vacancies";



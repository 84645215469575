import {createSelector} from "reselect";
import {userSelectorKeys} from "./selectorKeys";

const user_S = state => state.user;

const creatorFn = selectorKey => {
    return createSelector(user_S, data => data[selectorKey])
};

export const userSelectors = state => {
    const sData = {};

    userSelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
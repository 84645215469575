import {createSelector} from "reselect";
import {staticTextSelectorKeys} from "./selectorKeys";


const text_S = state => state.staticText;

const creatorFn = selectorKey => {
    return createSelector(text_S, data => data[selectorKey])
};

export const textSelectors = state => {
    const sData = {};

    staticTextSelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
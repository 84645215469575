import React from 'react'
import azdararir from '../../../assets/images/azdararir.gif'

export const Attention = () => {
    return (
        <div className='attention'>
            <a href="https://azdararir.am/am/" target="_blank" rel="noopener noreferrer">
                <img src={azdararir} alt="Azdararir.am" />
            </a>
        </div>
    )
}

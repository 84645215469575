// Import packages
import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import utils
import { generateMediaUrl } from "../utils/generateMediaUrl";

// Import styles
import '../../assets/styles/memberCard.scss'
import { createUrl } from "../utils/createUrl";

export default class memberCard extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props){
        super(props)
    }
    render() {
        const {data} = this.props;

        return <div className="member-card">
            <div className="member-card-img">
                <div className="img-wrapper">
                    <img src={generateMediaUrl(data.memberMedia && data.memberMedia.path)}
                         alt={data.memberMedia ? data.memberMedia.altAttribute : ''}/>
                </div>
            </div>
            {data && <div className="member-card-content">
                <Link to={{
                    pathname: createUrl('members', data.id),
                }}>
                    <div className="member-card-name">{data.firstName} {data.lastName}</div>
                </Link>

                <div className="member-card-profession">{data.profession}</div>
            </div>}
        </div>
    }
}

import {createSelector} from "reselect";
import {newsSelectorKeys} from "./selectorKeys";

const news_S = state => state.news;

const creatorFn = selectorKey => {
    return createSelector(news_S, data => data[selectorKey])
};

export const newsSelectors = state => {
    const sData = {};

    newsSelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
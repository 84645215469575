// Import packages
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

// Import styles
import "../../../assets/styles/homePage/about.scss"

// Import components
import {Collapse} from 'antd';

// Import utils
import {mapStateToProps} from "../../../admin/redux/mapStateToProps";
import {GetMenuWeb, GetMembers, GetVacancies} from "../../redux/actions";
import {generateMediaUrl} from "../../utils/generateMediaUrl";
import {MENU_TYPES} from "../../../admin/constants/constants";
import {history} from "../../config/history";
import { createUrl } from "../../utils/createUrl";

const {Panel} = Collapse;

class About extends Component {
    componentDidMount() {
        this.props.GetMembers(true);
        this.props.GetMenuWeb(MENU_TYPES.RIGHT);
        this.props.GetVacancies();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetMembers(true);
            this.props.GetMenuWeb(MENU_TYPES.RIGHT);
        }
    }

    render() {
        const {rightMenu, chief, translation, vacanciesPageData} = this.props;
        return (
            !!chief && <div className="about">
                <div className="about-person">
                    <div className="about-header">
                        <div className="about-header-img-content">
                            <div className="about-header-img">
                                <img
                                    src={generateMediaUrl(chief.memberMedia && chief.memberMedia.path)}
                                    alt={chief.memberMedia && chief.memberMedia.altAttribute}/>
                            </div>
                        </div>
                        <div className="about-header-inner">
                            <Link to={{
                                pathname: createUrl("director"),
                                state: {isChief: true}
                            }}>
                                <p className="about-name">{chief.firstName} {chief.lastName}</p>
                            </Link>
                            <p className="about-position">{chief.profession}</p>
                            {/*<Link to={{
                                pathname: "director",
                                state: {isChief: true}
                            }}>
                                <button className="see-more">
                                    {translation && translation['director-button-text']}
                                    <img src={require('../../../assets/images/icons/ic_arrow_more.svg')}/>
                                </button>
                            </Link>*/}
                        </div>
                    </div>
                    <div>
                        <pre className="about-description">
                                {translation && translation['director-greetings-text']}
                        </pre>
                    </div>
                </div>
                <div className="about-panel">
                    <Collapse accordion
                              expandIconPosition={'right'}
                              expandIcon={(value) => {
                                  return <img className={value.isActive ? 'rotate' : ''}
                                              src={require('../../../assets/images/icons/ic_arrow_down_category.svg')}
                                              alt=""/>
                              }}>
                        {rightMenu && rightMenu.map((item) => {
                            return <Panel header={item.title} key={item.id}
                                          showArrow={!!item.subMenu.length}
                                          extra={<div className={'collapse-item-wrapper'}
                                                      onClick={() => !item.subMenu.length && history.push(createUrl(item.url))}/>}
                                          className={!item.subMenu.length ? 'hide' : ''}>
                                {
                                    item.subMenu.map((item) => {
                                        return <p key={item.id}
                                                  onClick={() => history.push(createUrl(item.url))}>{item.title}</p>
                                    })
                                }
                            </Panel>
                        })}
                    </Collapse>


                    {vacanciesPageData && Array.isArray(vacanciesPageData) && !!vacanciesPageData.length && <>
                        <div className="empty"></div>
                        <button
                            onClick={() => history.push(createUrl('vacancies'))}>{translation && translation['right-menu-button-text']}
                            <img src={require('../../../assets/images/icons/ic_arrow_more_white.svg')}
                                 alt=""/>
                        </button>
                    </>}

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    GetMenuWeb,
    GetMembers,
    GetVacancies
};

export default connect(mapStateToProps, mapDispatchToProps)(About);

import {createSelector} from "reselect";
import {selectorKeys} from "./selectorKeys";

const web_S = state => state.web;

const creatorFn = selectorKey => {
    return createSelector(web_S, data => data[selectorKey])
};

 const webSelector = state => {
    const sData = {};

    selectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
export default webSelector;
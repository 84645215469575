// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Import utils
import { mapStateToProps } from "../../../admin/redux/mapStateToProps";
import { GetSliderWeb } from "../../redux/actions";
import { generateMediaUrl } from "../../utils/generateMediaUrl";

// Import components
import Slider from "react-slick";

// Import styles
import "../../../assets/styles/homePage/slider.scss";
import { createUrl } from "../../utils/createUrl";

class SimpleSlider extends Component {
    constructor() {
        super();
        this.state = {
            nav1: null,
            nav2: null
        };
        this.sliderRef = React.createRef();
    }

    componentDidMount() {
        this.props.GetSliderWeb();
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedLanguage } = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetSliderWeb();
        }
    }

    render() {
        let { slider } = this.props;
        slider = slider && slider.filter(slid => slid.relatedNews);
        const settingsImages = {
            dots: false,
            arrows: false,
            infinite: !(slider && slider.length < 2),
            centerMode: true,
            centerPadding: " 60px 0 0",
            speed: 500,
            autoplay: true,
            customPaging: function (i) {
                return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a className="slider-dot-link">
                        {i < 10 ? 0 : ''}{i + 1}
                    </a>
                );
            },
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: Math.floor((slider && slider.length - 1) / 2),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        centerPadding: " 40px 0 0",
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 756,
                    settings: {
                        centerPadding: "100px 0 0",
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        centerPadding: " 60px 0 0",
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        centerPadding: " 30px 0 0",
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        centerPadding: "15px 0 0",
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const settingsDots = {
            dots: false,
            arrows: false,
            asNavFor: this.state.nav1,
            ref: slider => (this.slider2 = slider),
            swipeToSlide: true,
            focusOnSelect: true,
            infinite: true,
            centerMode: true,
            centerPadding: "0",
            slidesToShow: slider && (slider.length <= 5) ? 3 : 5,
        };

        return (
            <div className="home-page-slider">
                <Slider {...settingsImages} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)}>
                    {slider && slider.map((item) => {
                        return !!item && item.relatedNews && <div className={"slider-item"} key={item.id}>
                            <div className="slide-overlay" />
                            {<img src={generateMediaUrl(item.relatedNews.mediaMain && item.relatedNews.mediaMain.path)}
                                alt={item.relatedNews.mediaMain ? item.relatedNews.mediaMain.altAttribute : ''} />}
                            <Link to={{
                                pathname: createUrl('news', item.relatedNews.slug || item.relatedNews.id),
                                state: { newsId: item.relatedNews.id }
                            }}>
                                <p className={"slider-text"}>{item.relatedNews.title}</p>
                            </Link>
                        </div>
                    })}
                </Slider>

                <div>
                    {
                        !!slider && !!slider.length && <div className={'slider-arrows'}>
                            <div className={'slider-btn  prev-btn'} onClick={() => this.slider1.slickPrev()}>
                                <img src={require('../../../assets/images/ic_white_left_arrow.svg')} alt="icon" />
                            </div>
                            <div className={'slider-btn next-btn'} onClick={() => this.slider1.slickNext()}>
                                <img src={require('../../../assets/images/ic_white_right_arrow.svg')} alt="icon" />
                            </div>
                        </div>
                    }

                    <div className="slider-numbers">
                        <Slider {...settingsDots}>
                            {
                                slider && slider.map((item, index) => {
                                    return <div className="slider-dot-link" key={item.id}>
                                        {index < 9 ? 0 : ''}{index + 1}
                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    GetSliderWeb,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleSlider);

import {createSelector} from "reselect";
import {institutionSelectorKeys} from "./selectorKeys";

const institution_S = state => state.institutions;

const creatorFn = selectorKey => {
    return createSelector(institution_S, data => data[selectorKey])
};

export const institutionSelectors = state => {
    const sData = {};

    institutionSelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
// Import packages
import React, { lazy, Suspense } from "react";
import { Route, Router, Switch } from "react-router-dom";

// Import utils
// import { WEBPAGES, LANGUAGES } from "../constants/constants";
import { WEBPAGES } from "../constants/constants";
import { history } from "../config/history";

// Import pages
import Layout from "../pages/Layout";
import { Attention } from "../components/attention/Attention";
import Homepage from "../pages/Homepage";
import Videos from "../pages/Videos";
import CustomPage from "../pages/CustomPage";

import News from "../pages/News";
import NewsDetails from "../pages/NewsDetails";

import Members from "../pages/Members";
import MemberDetails from "../pages/MemberDetails";

import Institutions from "../pages/Institutions";
import InstitutionDetails from "../pages/InstitutionDetails";

import Contact from "../pages/Contact";
import NotFound from "../pages/NotFound";
import Vacancies from "../pages/Vacancies";
import Search from "../pages/Search";


// Import admin Route
const AdminRoute = lazy(() => import("./AdminRoute"));

export default () => {
    const { location } = history;

    // function getRoutes(page, ending = ''){
    //     const routes = [];
    //     LANGUAGES.forEach(lg => {
    //         const languageCode = lg === 'hy' ? '' : `${lg}/`;
    //         if ( page !== '' ) {
    //             routes.push(`/${languageCode}${WEBPAGES[page][lg]}${ending ? ending : ''}`)
    //         } else {
    //             routes.push(lg === 'hy' ? ending : `/${lg}${ending}`)
    //         }
    //     })
    //     return routes
    // }

    function getRoutes(page, ending = '') {
        const routes = [];

        if (page !== '') {
            routes.push(`/${WEBPAGES[page]}${ending ? ending : ''}`);
        } else {
            routes.push(ending);
        }

        return routes;
    }

    return <Router history={history}>

        {location && !location.pathname.includes("admin") ?
            <Layout>
                <Attention />
                <Switch>
                    <Route exact path={getRoutes('', '/')} component={Homepage} />
                    <Route exact path={getRoutes('news')} component={News} />
                    <Route exact path={getRoutes('news', '/:slug')} component={NewsDetails} />
                    <Route exact path={getRoutes('videos')} component={Videos} />
                    <Route exact path={getRoutes('institutions')} component={Institutions} />
                    <Route exact path={getRoutes('institutions', '/:id')} component={InstitutionDetails} />
                    <Route exact path={getRoutes('members')} component={Members} />
                    <Route exact path={getRoutes('members', '/:id')} component={MemberDetails} />
                    <Route exact path={getRoutes('director')} component={MemberDetails} />
                    <Route exact path={getRoutes('contact')} component={Contact} />
                    <Route exact path={getRoutes('vacancies')} component={Vacancies} />
                    <Route exact path={getRoutes('search')} component={Search} />
                    <Route exact path={getRoutes('not-found')} component={NotFound} />
                    <Route exact path={getRoutes('', '/:slug')} component={CustomPage} />
                    <Route component={NotFound} />
                </Switch>
            </Layout> :
            <Suspense fallback={<div></div>}>
                <AdminRoute />
            </Suspense>}
    </Router>
}

/*<Switch>
                        <Route exact path="/" component={Homepage}/>
                        <Route exact path="/news" component={News}/>
                        <Route exact path="/news/:slug" component={NewsDetails}/>
                        <Route exact path="/videos" component={Videos}/>
                        <Route exact path="/institutions" component={Institutions}/>
                        <Route exact path="/institutions/:id" component={InstitutionDetails}/>
                        <Route exact path="/members" component={Members}/>
                        <Route exact path="/member-details/:id" component={MemberDetails}/>
                        <Route exact path="/director" component={MemberDetails}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/vacancies" component={Vacancies}/>
                        <Route exact path="/search" component={Search}/>
                        <Route exact path="/not-found" component={NotFound}/>
                        <Route exact path="/:slug" component={CustomPage}/>
                        <Route component={NotFound}/>
                    </Switch>*/

import {createSelector} from "reselect";
import {roleSelectorKeys} from "./selectorKeys";

const role_S = state => state.role;

const creatorFn = selectorKey => {
    return createSelector(role_S, data => data[selectorKey])
};

export const roleSelectors = state => {
    const sData = {};

    roleSelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
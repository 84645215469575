import React from "react";
import { Dropdown, Menu } from "antd";
import { Link, NavLink } from "react-router-dom";
import { createUrl } from "./createUrl";

const { SubMenu } = Menu;

export function getNavigationMenu(navMenu, searchClosed, mobileMenuHidden) {
    console.log(navMenu);//
    return navMenu.map((item) => {
        return item.subMenu && !!item.subMenu.length ? (
            <li
                key={item.id}
                className={`list-item ${searchClosed ? 'is-closed' : ''} ${mobileMenuHidden ? 'hidden' : ''}`}
            >
                <Dropdown
                    overlay={
                        <Menu>
                            {item.subMenu.map((subItem) => (
                                <Menu.Item key={subItem.id}>
                                    <NavLink
                                        exact
                                        strict
                                        to={{
                                            pathname: createUrl(subItem.url),
                                        }}
                                        activeStyle={{
                                            color: "#7295fe !important",
                                        }}
                                        style={subItem.title === "ԹԱՓՈՒՐ ՀԱՍՏԻՔՆԵՐ" ? { color: "red" } : null}
                                    >
                                        {subItem.title}
                                    </NavLink>
                                </Menu.Item>
                            ))}
                        </Menu>
                    }
                >
                    <span className="ant-dropdown-link">
                        {item.title}
                        <img src={require('../../assets/images/icons/ic_drop_down.svg')} alt="" />
                    </span>
                </Dropdown>
            </li>
        ) : (
            <li
                key={item.id}
                className={`list-item ${searchClosed ? 'is-closed' : ''} ${mobileMenuHidden ? 'hidden' : ''}`}
            >
                <NavLink
                    exact
                    strict
                    to={{
                        pathname: createUrl(item.url),
                    }}
                    activeStyle={{
                        color: '#7295fe',
                    }}
                    style={item.title === "ԹԱՓՈՒՐ ՀԱՍՏԻՔՆԵՐ" ? { color: "red" } : { color: "#000000" }}
                >
                    {item.title}
                </NavLink>
            </li>
        );
    });
}

export function getMobileNavigationMenu(mobileMenu, showNav) {
    return mobileMenu && mobileMenu.map((item, index) => {
        return item.subMenu && !!item.subMenu.length ? (
            <SubMenu
                key={item.id}
                className={'menu-item'}
                style={{
                    transitionDelay: showNav ? `${index * 0.06 + 0.2}s` : `${0.3 - index * 0.04}s`,
                }}
                title={item.title}
            >
                {item.subMenu.map((subCategory) =>
                    subCategory.subMenu && !!subCategory.subMenu.length ? (
                        <SubMenu key={subCategory.id} className={'subMenu'} title={subCategory.title}>
                            {subCategory.subMenu.map((subItem) => (
                                <Menu.Item key={subItem.id} className={'menu-item'}>
                                    <Link to={{ pathname: createUrl(subItem.url) }}>{subItem.title}</Link>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    ) : (
                        <Menu.Item key={subCategory.id} className={'menu-item'}>
                            <Link to={{ pathname: createUrl(subCategory.url) }}>{subCategory.title}</Link>
                        </Menu.Item>
                    )
                )}
            </SubMenu>
        ) : (
            <Menu.Item
                key={item.id}
                className={'menu-item'}
                style={{
                    transitionDelay: showNav ? `${index * 0.06 + 0.2}s` : `${0.3 - index * 0.04}s`,
                }}
            >
                <Link to={{ pathname: createUrl(item.url) }}>{item.title}</Link>
            </Menu.Item>
        );
    });
}

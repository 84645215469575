// Import packages
import React, {Component} from "react";
import {connect} from 'react-redux'

// Import styles
import "../../assets/styles/pages/vacancies.scss"

// Import components
import PageHeader from "../components/dataDisplay/PageHeader";

// Import utils
import {
    GetVacancies,
    SetMetaData,
    ClearMetaData
} from "../redux/actions";
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {VacanciesType_Const} from "../../admin/constants/constants";
import { history } from "../config/history";
import { createUrl } from "../utils/createUrl";
import MetaTags from "../components/MetaTags";

class Vacancies extends Component {
    state = {
        selectedPage: '',
        positionPage: null,
        workPlacePage: null,
    };


    async componentDidMount() {
        await this.props.GetVacancies();
        const {vacanciesPageData} = this.props;
        const positionPage = vacanciesPageData && vacanciesPageData.find(page => page.type === VacanciesType_Const.POSITIONS);
        const workPlacePage = vacanciesPageData && vacanciesPageData.find(page => page.type === VacanciesType_Const.WORKPLACE);
        const priorityPage = positionPage ? positionPage : workPlacePage;
        this.props.SetMetaData({
            title: priorityPage && priorityPage.metaTitle,
            description: priorityPage && priorityPage.metaDescription
        });
        priorityPage
        && this.setState({
            selectedPage: priorityPage && priorityPage.type,
            positionPage,
            workPlacePage,
        })
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            await this.props.GetVacancies();
            history.push(createUrl('vacancies'))
            const {vacanciesPageData} = this.props;
            const positionPage = vacanciesPageData && vacanciesPageData.find(page => page.type === VacanciesType_Const.POSITIONS);
            const workPlacePage = vacanciesPageData && vacanciesPageData.find(page => page.type === VacanciesType_Const.WORKPLACE);
            vacanciesPageData && vacanciesPageData[0]
            && this.setState({
                positionPage,
                workPlacePage,
            })
        }
    }

    componentWillUnmount() {
        this.props.ClearMetaData()
    }

    toggleSelectedPage = (type) => {
        const {positionPage, workPlacePage} = this.state;
        const pageData = type === VacanciesType_Const.POSITIONS ? positionPage : workPlacePage;
        this.props.SetMetaData({
            title: pageData && pageData.metaTitle,
            description: pageData && pageData.metaDescription
        });
        this.setState({
            selectedPage: type
        })
    };

    render() {
        const {selectedPage, positionPage, workPlacePage} = this.state;
        const {translation} = this.props;
        const pageData = selectedPage === VacanciesType_Const.POSITIONS ? positionPage : workPlacePage;
        const pageHeader = {
            title: translation && translation['vacancy-page-title'],
            slider: false,
            filter: true,
        };
        const pageTitles = [];
        positionPage && pageTitles.push({
            id: positionPage.type,
            title: positionPage.title
        });
        workPlacePage && pageTitles.push({
            id: workPlacePage.type,
            title: workPlacePage.title
        });
        return <div className="vacancies-page">
            <MetaTags title={translation && translation['vacancy-page-title']}/>
            <PageHeader pageHeader={pageHeader}
                        categoryList={pageTitles}
                        selectedCategory={selectedPage}
                        toggleSelectedCategory={this.toggleSelectedPage}/>

            {pageData && pageData.content && <div className="vacancies-content ck-content-wrapper"
                                                  dangerouslySetInnerHTML={{__html: pageData.content}}/>}
        </div>
    }
}

const mapDispatchToProps = {
    GetVacancies,
    SetMetaData,
    ClearMetaData
}
export default connect(mapStateToProps, mapDispatchToProps)(Vacancies)

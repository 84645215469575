/* eslint-disable no-mixed-operators */
//import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import "../../assets/styles/pages/searchPage.scss";

//import components
import InfiniteScroll from "react-infinite-scroller";
import PageHeader from "../components/dataDisplay/PageHeader";
import NewsCard from "../components/NewsCard";
import {Menu, Dropdown} from 'antd';

//import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {LIMIT_GET_NEWS, NEWS_MEDIA_TYPES} from "../../admin/constants/constants";
import {GetSearchedNews, SetSelectedCategory, ClearSearchedData} from "../redux/actions";
import VideoCard from "../components/VideoCard";
import { history } from "../config/history";
import { createUrl } from "../utils/createUrl";


class Search extends Component {
    constructor() {
        super();
        this.state = {
            searchType: NEWS_MEDIA_TYPES.MAIN,
            currentPlayingVideo: null
        }
        this.getNews = this.getNews.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.toggleSearchType = this.toggleSearchType.bind(this);
        this.toggleCurrentPlayingVideo = this.toggleCurrentPlayingVideo.bind(this);
    }

    componentDidMount() {
        const searchText = this.props.location && this.props.location.state && this.props.location.state.searchText;
        this.props.GetSearchedNews({searchText})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage, searchedData} = this.props;
        const prevSearchText = prevProps.location && prevProps.location.state && prevProps.location.state.searchText;
        const newSearchText = this.props.location && this.props.location.state && this.props.location.state.searchText;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            history.push(createUrl('search'))
            searchedData && this.props.GetSearchedNews({
                categoryId: searchedData.categoryId,
                searchText: searchedData.searchText,
                type: this.state.searchType
            });
        }
        if (prevSearchText !== newSearchText) {
            searchedData && this.props.GetSearchedNews({
                categoryId: searchedData.categoryId,
                searchText: newSearchText,
                type: this.state.searchType
            });
        }
        if (prevState.searchType !== this.state.searchType) {
            searchedData && this.props.GetSearchedNews({
                categoryId: searchedData.categoryId,
                searchText: newSearchText,
                type: this.state.searchType
            });
        }
    }

    componentWillUnmount() {
        this.props.ClearSearchedData();
    }

    getNews(id) {
        const {searchedData} = this.props;
        this.props.GetSearchedNews({
            categoryId: id,
            searchText: searchedData.searchText,
            type: this.state.searchType
        });
    }

    loadMore() {
        const {searchedData} = this.props;
        if (searchedData.hasMore) {
            const offset = searchedData.itemsList && searchedData.itemsList.length;
            this.props.GetSearchedNews({
                reset: false,
                categoryId: searchedData.categoryId,
                offset,
                type: this.state.searchType,
                searchText: searchedData.searchText,
                limit: LIMIT_GET_NEWS
            });
        }
    }

    toggleSearchType(searchType) {
        this.setState({
            searchType: searchType
        })
    }

    toggleCurrentPlayingVideo(id) {
        this.setState({
            currentPlayingVideo: id
        })
    }

    render() {
        const {searchedData,translation} = this.props;
        const {searchType} = this.state;

        const MediaTypesMenu = (<Menu>
                {searchType !== NEWS_MEDIA_TYPES.VIDEO && <Menu.Item onClick={() => {
                    this.toggleSearchType(NEWS_MEDIA_TYPES.VIDEO)
                }}>
                    {translation && translation['videos-page-title']}
                </Menu.Item>}
                {searchType !== NEWS_MEDIA_TYPES.MAIN && <Menu.Item onClick={() => {
                    this.toggleSearchType(NEWS_MEDIA_TYPES.MAIN)
                }}>
                    {translation && translation['news-page-title']}
                </Menu.Item>}
            </Menu>
        );
        const pageHeader = {
            title: translation && translation['search-page-title'],
            slider: true,
            filter: false,
        };
        return <div className="search-page">

            <div className="search-header">
                <PageHeader pageHeader={pageHeader}  getNews={this.getNews}/>

                <Dropdown overlay={MediaTypesMenu}>
                <span className={'ant-dropdown-link'}>
                    {searchType === NEWS_MEDIA_TYPES.MAIN ? translation && translation['news-page-title'] : translation && translation['videos-page-title']}
                    <img
                         src={require('../../assets/images/ic_arrow_down_category.svg')}
                         alt=""/>
                </span>
                </Dropdown>
            </div>

            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={this.loadMore}
                className={'search-content'}
                hasMore={searchedData && searchedData.hasMore}>

                {searchedData && searchedData.itemsList && searchedData.itemsList.length ? searchedData.itemsList.map(item => {
                        return searchedData.type === NEWS_MEDIA_TYPES.MAIN ?
                            <NewsCard news={item} key={item.id}/>
                            : <VideoCard
                                card={item}
                                key={item.id}
                                currentPlayingVideo={this.state.currentPlayingVideo}
                                toggleCurrentPlayingVideo={this.toggleCurrentPlayingVideo}
                            />
                    }) :
                    <div className={'no-content-wrapper'}>
                        <img src={require('../../assets/images/no_search_result.svg')} alt=""/>
                        <span className="text-message">{translation && translation["search-notFound-text"] || ""}</span>
                    </div>
                }

            </InfiniteScroll>
        </div>

    }
}

const mapDispatchToProps = {
    GetSearchedNews,
    SetSelectedCategory,
    ClearSearchedData
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

//import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import "../../assets/styles/pages/news.scss";

//import components
import MetaTags from '../components/MetaTags'
import InfiniteScroll from "react-infinite-scroller";
import PageHeader from "../components/dataDisplay/PageHeader";
import NewsCard from "../components/NewsCard";

//import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {LIMIT_GET_NEWS} from "../../admin/constants/constants";
import {GetAllNewsWeb, SetSelectedCategory} from "../redux/actions";
import { history } from "../config/history";
import { createUrl } from "../utils/createUrl";

// const spinIcon = <Icon type="loading" style={{fontSize: 24, color: "#b3224a"}} spin/>;

class News extends Component {
    constructor() {
        super();
        this.state = {
            loading: false
        };
        this.getNews = this.getNews.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    async componentDidMount() {
        const categoryId = this.props.selectedCategoryId;
        await this.setState({
            loading: true
        })
        await this.props.GetAllNewsWeb({categoryId: categoryId});
        this.setState({
            loading: false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage, newsList} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            newsList && this.props.GetAllNewsWeb({
                categoryId: newsList.categoryId,
            });
            history.push(createUrl('news'))
        }
    }

    getNews(id) {
        this.props.GetAllNewsWeb({
            categoryId: id,
        });
    }

    loadMore() {
        const {newsList} = this.props;

        if (newsList.hasMore && !this.state.loading) {
            const offset = newsList.itemsList && newsList.itemsList.length;
            this.props.GetAllNewsWeb({
                reset: false,
                categoryId: newsList.categoryId,
                offset,
                limit: LIMIT_GET_NEWS
            });
        }
    }

    render() {
        const {newsList, translation} = this.props;

        const pageHeader = {
            title: translation && translation['news-page-title'],
            slider: true,
            filter: false,
        };
        return <div className="news-page">
            <MetaTags title={translation && translation['news-page-title']}/>
            <PageHeader pageHeader={pageHeader} getNews={this.getNews}/>
            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={this.loadMore}
                className={'news-content'}
                hasMore={newsList && newsList.hasMore}>

                {newsList && newsList.itemsList && newsList.itemsList.map(item => {
                    return <NewsCard news={item} key={item.id}/>
                })}

            </InfiniteScroll>
        </div>

    }
}

const mapDispatchToProps = {
    GetAllNewsWeb,
    SetSelectedCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(News);

export const selectorKeys = [
    "metaTags",
    "languages",
    'selectedLanguage',
    'menuList',
    'rightMenu',
    'slider',
    'categories',
    'selectedCategoryId',
    'newsList',
    'videosList',
    "newsDetails",
    'institutions',
    'institutionData',
    'members',
    'memberData',
    'singleMember',
    'chief',
    'contacts',
    'customPageData',
    'searchedData',
    'vacanciesPageData',
    'translationsData',
    'translation',
];

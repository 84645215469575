export const authSelectorKeys = ["isLoggedIn", "accessToken", "isAdmin", "scope",
    "username", "staticPages", "accessPages"];
export const userSelectorKeys = ["usersList"];
export const roleSelectorKeys = ["rolesList"];
export const newsSelectorKeys = ["newsList", "singleNews", "newsListForSlider"];
export const languagesSelectorKeys = ["languages", "mainLanguage"];
export const utilSelectorKeys = ["leftSideMenuOpen", "requestLoading","redirectedFirstTime","firstAccessedPage"];
export const categorySelectorKeys = ["categoriesList"];
export const gallerySelectorKeys = ["galleryImages"];
export const menuSelectorKeys = ["menuList", "rightMenuList", "singleMenu"];
export const pageSelectorKeys = ["pageList"];
export const contactSelectorKeys = ["contactData"];
export const memberSelectorKeys = ["teamMembers", "singleMember"];
export const institutionSelectorKeys = ["institutionList"];
export const staticTextSelectorKeys = ["staticTextsList"];

export const fileSelectorKeys = ["tempFileUrl"];

export const sliderSelectorKeys = ["slidersList", "singleSlider"];







import {authConsts, teamConsts} from "../constants";

const initialState = {
    teamMembers: [],
    singleMember: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case teamConsts.GET_MEMBERS_LIST:
            return {
                ...state,
                teamMembers: action.payload
            };
        case teamConsts.GET_TEAM_MEMBER_BY_ID:
            return {
                ...state,
                singleMember: action.payload
            };
        case teamConsts.DELETE_TEAM_MEMBER_BY_ID:
            return {
                ...state,
                teamMembers: state.teamMembers.filter(member => member.id !== action.payload)
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
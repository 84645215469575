import {consts} from "../constants";
import {request} from "../api/request";
import {
    _urlCategories,
    _urlContact,
    _urlInstitutions,
    _urlLanguage,
    _urlMembers,
    _urlMenu,
    _urlNews,
    _urlSlider,
    _urlCustomPage,
    _urlVacancies,
} from "../api/url";
import {_urlStatic} from "../../../admin/redux/api/urls";
// import swal from "sweetalert";
import {MENU_TYPES, NEWS_MEDIA_TYPES} from "../../../admin/constants/constants";
import {history} from "../../config/history";

export const GetLanguages = () => {
    return dispatch => {
        return request(_urlLanguage, "GET", false)
            .then(res => {
                dispatch({
                    type: consts.GET_ALL_LANGUAGE_WEB,
                    payload: res.data
                })
            })
            .catch(() => {
                // swal({
                //     title: "Oops!",
                //     text: "Something went wrong. Please try again.",
                //     icon: "warning",
                //     button: "Ok"
                // });
            })
    }
};

export const GetTranslations = () => {
    return dispatch => {
        return request(`${_urlStatic}`, "GET", false)
            .then(res => {
                dispatch({
                    type: consts.SET_TRANSLATIONS,
                    payload: res.data
                });
                return res.data
            }).catch(()=>{})
    }
};

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: consts.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

export const GetMenuWeb = (type = MENU_TYPES.MAIN) => {
    let url = `${_urlMenu}?type=${type}`;
    let actionType = type === MENU_TYPES.MAIN ? consts.GET_MENU_WEB : consts.GET_RIGHT_MENU_WEB;
    return dispatch => {
        return request(url, "GET", true)
            .then(res => {
                dispatch({
                    type: actionType,
                    payload: res.data
                })
            })
            .catch((error) => {
                // swal({
                //     title: "Oops!",
                //     text: "Something went wrong. Please try again.",
                //     icon: "warning",
                //     button: "Ok"
                // });
            })
    }
};

export const GetSliderWeb = () => {
    return dispatch => {
        return request(_urlSlider, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.GET_SLIDER_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {
                // swal({
                //     title: "Oops!",
                //     text: "Something went wrong. Please try again.",
                //     icon: "warning",
                //     button: "Ok"
                // });
            })
    }
};

export const GetCategoriesWeb = () => {
    return dispatch => {
        return request(_urlCategories, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.GET_CATEGORIES_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {
                // swal({
                //     title: "Oops!",
                //     text: "Something went wrong. Please try again.",
                //     icon: "warning",
                //     button: "Ok"
                // });
            })
    }
};

export const SetSelectedCategory = (data) => {
    return dispatch => {
        dispatch({
            type: consts.GET_CATEGORY_ID_WEB,
            payload: data
        })
    }
};

export const GetWebNewsById = id => {
    let url = `${_urlNews}/${id}`;
    return dispatch => {
        return request(url, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.GET_WEB_NEWS_BY_ID,
                    payload: res.data
                })
            })
    }
};

export const ClearNewsDetails = () => {
    return dispatch => {
        dispatch({
            type: consts.CLEAR_NEWS_DETAILS,

        })
    }
};

export const GetAllNewsWeb = ({reset = true, offset = 0, limit = 20, categoryId = '', searchText = '', type = NEWS_MEDIA_TYPES.MAIN} = {}) => {

    let url = `${_urlNews}?offset=${offset}&limit=${limit}&type=${type}`;
    if (searchText) url += `&name=${searchText}`;
    if (categoryId) url += `&categoryIds=${categoryId}`;

    let actionType = type === NEWS_MEDIA_TYPES.MAIN ? consts.GET_ALL_NEWS_WEB : consts.GET_ALL_VIDEOS_WEB;

    return dispatch => {
        return request(url, "GET", true)
            .then(async res => {
                await dispatch({
                    type: actionType,
                    payload: {
                        data: res.data,
                        reset,
                        categoryId,
                        searchText,
                        hasMore: res.data.length === limit
                    }
                })
            })
            .catch((error) => {
                // swal({
                //     title: "Oops!",
                //     text: "Something went wrong. Please try again.",
                //     icon: "warning",
                //     button: "Ok"
                // });
            })
    }
};

export const GetSearchedNews = ({reset = true, offset = 0, limit = 20, categoryId = '', searchText = '', type = NEWS_MEDIA_TYPES.MAIN} = {}) => {

    let url = `${_urlNews}?offset=${offset}&limit=${limit}&type=${type}`;
    if (searchText) url += `&name=${searchText}`;
    if (categoryId) url += `&categoryIds=${categoryId}`;


    return dispatch => {
        return request(url, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_SEARCH_DATA,
                    payload: {
                        data: res.data,
                        reset,
                        categoryId,
                        searchText,
                        type,
                        hasMore: res.data.length === limit
                    }
                })
            })
            .catch((error) => {
                // swal({
                //     title: "Oops!",
                //     text: "Something went wrong. Please try again.",
                //     icon: "warning",
                //     button: "Ok"
                // });
            })
    }
};

export const ClearSearchedData = () => {
    return dispatch => {
        dispatch({
            type: consts.CLEAR_SEARCH_DATA,
        })
    }
};

export const GetInstitutionsWeb = () => {
    return dispatch => {
        return request(_urlInstitutions, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.GET_INSTITUTIONS_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {
                // swal({
                //     title: "Oops!",
                //     text: "Something went wrong. Please try again.",
                //     icon: "warning",
                //     button: "Ok"
                // });
            })
    }
};

export const GetInstitutionById = (id) => {
    return dispatch => {
        return request(`${_urlInstitutions}/${id}`, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.GET_INSTITUTION_BY_ID,
                    payload: res.data
                })
            })
            .catch((error) => {
                history.push('/institutions')
            })
    }
};

export const ClearInstitutionById = () => {
    return dispatch => {
        dispatch({
            type: consts.CLEAR_INSTITUTION_BY_ID,
        })
    }
};

export const GetMembers = (getChief = false) => {
    let url = _urlMembers;
    if (getChief) {
        url = `${_urlMembers}/chief`;
    }

    let actionType = getChief ? consts.GET_CHIEF_WEB : consts.GET_MEMBERS_WEB;
    let emptyData = getChief ? {} : [];
    return dispatch => {
        return request(url, "GET", true)
            .then(res => {
                dispatch({
                    type: actionType,
                    payload: res.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: actionType,
                    payload: emptyData
                })
                // swal({
                //     title: "Oops!",
                //     text: "Something went wrong. Please try again.",
                //     icon: "warning",
                //     button: "Ok"
                // });
            })
    }
};

export const GetMemberById = (id) => {
    return dispatch => {
        return request(`${_urlMembers}/${id}`, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.GET_MEMBER_BY_ID_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {
                history.push('/members')
            })
    }
};

export const ClearMemberById = () => {
    return dispatch => {
        dispatch({
            type: consts.CLEAR_MEMBER_BY_ID_WEB,
        })
    }
};

export const GetContactsWeb = () => {
    return dispatch => {
        return request(_urlContact, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.GET_CONTACT_WEB,
                    payload: res.data
                })
            })
            .catch((error) => {
                // swal({
                //     title: "Oops!",
                //     text: "Something went wrong. Please try again.",
                //     icon: "warning",
                //     button: "Ok"
                // });
            })
    }
};

export const SendContactsEmail = (data) => {
    return dispatch => {
        return request(_urlContact, "POST", false, data)
    }
};

export const GetCustomPage = (slug) => {
    return dispatch => {
        return request(`${_urlCustomPage}/${slug}`, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_CUSTOM_PAGE,
                    payload: res.data
                });
                dispatch({
                    type: consts.SET_META_DATA,
                    payload: {
                        title: res.data.title,
                        description: res.data.metaDescription
                    }
                });
                return res.data
            })
    }
};

export const GetVacancies = () => {
    return dispatch => {
        return request(`${_urlVacancies}`, "GET", true)
            .then(res => {
                dispatch({
                    type: consts.SET_VACANCIES_DATA,
                    payload: res.data
                });
                return res.data
            })
    }
};


export const ClearCustomPage = () => {
    return dispatch => {
        dispatch({
            type: consts.CLEAR_CUSTOM_PAGE,
        })
    }
};

export const SetMetaData = (data) => {
    return dispatch => {
        dispatch({
            type: consts.SET_META_DATA,
            payload: data
        })
    }
};
export const ClearMetaData = () => {
    return dispatch => {
        dispatch({
            type: consts.CLEAR_META_DATA,
        })
    }
};


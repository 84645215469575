export const consts = {
    SELECTED_LANGUAGE: "SELECTED_LANGUAGE",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    GET_ALL_LANGUAGE_WEB: "GET_ALL_LANGUAGE_WEB",

    GET_MENU_WEB: "GET_MENU_WEB",
    GET_RIGHT_MENU_WEB: "GET_RIGHT_MENU_WEB",

    GET_SLIDER_WEB: "GET_SLIDER_WEB",
    GET_CATEGORIES_WEB: "GET_CATEGORIES_WEB",
    GET_CATEGORY_ID_WEB: "GET_CATEGORY_ID_WEB",

    GET_ALL_NEWS_WEB: "GET_ALL_NEWS_WEB",
    GET_ALL_VIDEOS_WEB: "GET_ALL_VIDEOS_WEB",
    GET_WEB_NEWS_BY_ID: "GET_WEB_NEWS_BY_ID",
    CLEAR_NEWS_DETAILS: "CLEAR_NEWS_DETAILS",

    GET_CONTACT_WEB: "GET_CONTACT_WEB",


    //Institutions
    GET_INSTITUTIONS_WEB: "GET_INSTITUTIONS_WEB",
    GET_INSTITUTION_BY_ID: "GET_INSTITUTION_BY_ID",
    CLEAR_INSTITUTION_BY_ID: "CLEAR_INSTITUTION_BY_ID",



    //Members
    GET_MEMBERS_WEB: "GET_MEMBERS_WEB",
    GET_MEMBER_BY_ID_WEB: "GET_MEMBER_BY_ID_WEB",
    CLEAR_MEMBER_BY_ID_WEB: "CLEAR_MEMBER_BY_ID_WEB",
    GET_CHIEF_WEB: "GET_CHIEF_WEB",

    //Custom Page
    SET_CUSTOM_PAGE: "SET_CUSTOM_PAGE",
    CLEAR_CUSTOM_PAGE: "CLEAR_CUSTOM_PAGE",

   //Vacancies Page
    SET_VACANCIES_DATA: "SET_VACANCIES_DATA",

   //Static Texts
    SET_TRANSLATIONS: "SET_TRANSLATIONS",

    //Search Page
    SET_SEARCH_DATA: "SET_SEARCH_DATA",
    CLEAR_SEARCH_DATA: "CLEAR_SEARCH_DATA",

    //Meta Tags
    SET_META_DATA: "SET_META_DATA",
    CLEAR_META_DATA: "CLEAR_META_DATA",

};

import {createSelector} from "reselect";
import {memberSelectorKeys} from "./selectorKeys";

const member_S = state => state.member;

const creatorFn = selectorKey => {
    return createSelector(member_S, data => data[selectorKey])
};

export const memberSelectors = state => {
    const sData = {};

    memberSelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
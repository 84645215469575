// import { store } from "../../admin/redux/store";
import { WEBPAGES } from "../constants/constants";

// export function createUrl(page, param){
//     if ( page && page.startsWith('/') ) {
//         page = page.slice(1);
//     }
//     const lg = store?.getState()?.web?.selectedLanguage?.code || 'hy'
//     if ( !lg ) {
//         return '/'
//     }
//     if(!page){
//         return lg === 'hy' ? `/` : `/${lg}`
//     }

//     const staticPage = WEBPAGES[page];
//     if ( !staticPage ) {
//         return lg === 'hy' ? `/${page}` : `/${lg}/${page}`
//     }

//     const languageCode = lg === 'hy' ? '' : `${lg}/`
//     return `/${languageCode}${staticPage?.[lg]}${param ? ('/' + param) : ''}`;

// }

export function createUrl(page, param) {
    if (page && page.startsWith('/')) {
        page = page.slice(1);
    }

    const staticPage = WEBPAGES[page];
    if (!staticPage) {
        return `/${page}${param ? ('/' + param) : ''}`;
    }

    return `/${staticPage}${param ? ('/' + param) : ''}`;
}

// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Import utils
import { mapStateToProps } from "../../../admin/redux/mapStateToProps";
import { GetAllNewsWeb } from "../../redux/actions";

// Import components
import NewsCard from "../NewsCard";
// Import styles
import "../../../assets/styles/homePage/news.scss"
import { createUrl } from "../../utils/createUrl";


class News extends Component {
    async componentDidMount() {
        await this.props.GetAllNewsWeb({ limit: 4 });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedLanguage } = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetAllNewsWeb({ limit: 4 });
        }
    }

    render() {
        const { newsList, translation } = this.props;
        return (
            <div className="news">
                {
                    newsList && newsList.itemsList && !!newsList.itemsList.length
                    && <div className="news-title">
                        <h1 className="title">{translation && translation['home-news-section-title']}</h1>
                        <Link to={createUrl("news")}>
                            <p className="link">{translation && translation['home-news-link-text']}<img
                                src={require('../../../assets/images/icons/ic_arrow_more.svg')} alt="img" /></p>
                        </Link>
                    </div>
                }
                <div className="news-content">
                    {
                        newsList && newsList.itemsList && Array.isArray(newsList.itemsList)
                        && newsList.itemsList.slice(0, 4).map((item) => {
                            return <NewsCard news={item} key={item.id} />
                        })
                    }
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    GetAllNewsWeb
};

export default connect(mapStateToProps, mapDispatchToProps)(News);

import {authSelectors} from "./authSelectors";
import {utilSelectors} from "./utilSelectors";
import {userSelectors} from "./userSelectors";
import {roleSelectors} from "./roleSelectors";
import {newsSelectors} from "./newsSelectors";
import {textSelectors} from "./textSelectors";
import {languagesSelectors} from "./languageSelectors";
import {categorySelectors} from "./categorySelectors";
import {gallerySelectors} from "./gallerySelectors";
import {menuSelectors} from "./menuSelectors";
import {pageSelectors} from "./pageSelectors";
import {sliderSelectors} from "./sliderSelectors";
import {contactSelectors} from "./contactSelectors";
import {memberSelectors} from "./memberSelectors";
import {institutionSelectors} from "./institutionSelectors";

export default state => {
    return {
        ...authSelectors(state),
        ...userSelectors(state),
        ...roleSelectors(state),
        ...utilSelectors(state),
        ...newsSelectors(state),
        ...textSelectors(state),
        ...languagesSelectors(state),
        ...categorySelectors(state),
        ...gallerySelectors(state),
        ...menuSelectors(state),
        ...pageSelectors(state),
        ...sliderSelectors(state),
        ...contactSelectors(state),
        ...memberSelectors(state),
        ...institutionSelectors(state),
    }
}
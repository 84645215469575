import React, {useState} from "react";

import {MaterialIcon} from "../../../app/components/utils/MaterialIcon";
import {Dropdown, Select} from "antd";

import "../../../assets/styles/adminStyles/dataDisplay/inputGroup.scss";

export function InputGroup(props) {
    const {
        label, type, inputType, name, placeholder, error, options, autoComplete = "on", allowClear = true,
        defValue, step, onChange, onKeyDown, value, minValue, maxLength, autoFocus, disabled
    } = props;
    const [multiSelectIsOpen, toggleMultiSelect] = useState(false);

    const DropDownMenu = <div className="select-options">
        {options && !!options.length && options.map((item, index) => {
            return <div
                className={`option-item ${value && value[index] && value[index].isChecked ? "checked" : ""}`}
                key={index}
                onClick={() => onChange(value && value[index] && !value[index].isChecked, index)}>
                <input type="checkbox"
                       onChange={() => onChange(value && value[index] && !value[index].isChecked, index)}
                       checked={value && value[index] && value[index].isChecked}/>
                <span>{item.title}</span>
            </div>
        })}
    </div>;

    return <div className="input-group">

        {label && <label>{label}</label>}

        {inputType === "input" && type !== "number" && type !== "checkbox" && <>
            <input type={type} name={name} placeholder={placeholder} maxLength={maxLength}
                   value={value} defaultValue={defValue} onChange={onChange} onKeyDown={onKeyDown}
                   autoFocus={autoFocus} autoComplete={autoComplete} disabled={disabled}
                   className={`${error ? "invalid" : ""}`}/>
            {props.children}
        </>
        }

        {inputType === "input" && type === "number" &&
        <input type={type} name={name} value={value} min={minValue}
               step={step} onChange={onChange} disabled={disabled}
               className={`${error ? "invalid" : ""}`}/>}

        {inputType === "input" && type === "checkbox" &&
        <input type={type} name={name} checked={value} onChange={onChange}/>}

        {inputType === "textarea" &&
        <textarea name={name} placeholder={placeholder} value={value}
                  defaultValue={defValue} onChange={onChange} disabled={disabled}
                  className={`${error ? "invalid" : ""}`}/>}

        {inputType === "select" &&
        <select value={value || ''} name={name} placeholder={placeholder} onChange={onChange}
                className={`${error ? "invalid" : ""}`}>
            <option value={''} hidden selected></option>
            {options && !!options.length && options.map((item, index) => {
                return <option value={item.value} key={index}>{item.title}</option>
            })}
        </select>}
        {inputType === "custom-select" &&
        <Select value={value || undefined}
                style={{width: "100%"}}
                allowClear={allowClear}
                placeholder={placeholder}
                onChange={onChange}
                suffixIcon={<MaterialIcon icon="expand_more"/>}
                size={'large'}>
            {options && !!options.length && options.map((item, index) => {
                return <Select.Option value={item.value} key={index}>{item.title}</Select.Option>
            })}

        </Select>}

        {inputType === "multiSelect" &&
        <div className={`multi-select-wrapper ${multiSelectIsOpen ? "is-open" : ""}`}>
            <Dropdown overlay={DropDownMenu}
                      placement="bottomCenter"
                      visible={multiSelectIsOpen}
                      trigger={['click']}>
                <div className="select-header"
                     onClick={() => toggleMultiSelect(!multiSelectIsOpen)}>
                    {placeholder} <MaterialIcon icon="expand_more"/>
                </div>
            </Dropdown>
        </div>}
    </div>
}

// Import packages
import React, { Component } from "react";
import Slider from "react-slick";
import YouTubePlayer from 'react-player/lib/players/YouTube'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from 'moment';

// Import utils
import { mapStateToProps } from "../../admin/redux/mapStateToProps";
import { ClearNewsDetails, GetAllNewsWeb, GetWebNewsById, SetSelectedCategory } from "../redux/actions";
import { generateMediaUrl } from "../utils/generateMediaUrl";
import { NEWS_MEDIA_TYPES } from "../../admin/constants/constants";
import { history } from "../config/history";

// Import components
import MetaTags from '../components/MetaTags'
import NewsCard from "../components/NewsCard";
import VideoCard from "../components/VideoCard";
import { SliderModal } from "../components/dataDisplay/SliderModal";
import { Icon } from "antd";
// import { FacebookProvider, Like } from 'react-facebook';

// Import styles
import "../../assets/styles/pages/newsDetails.scss"
import { createUrl } from "../utils/createUrl";


class NewsDetails extends Component {
    constructor() {
        super();
        this.state = {
            alertDialogOpen: false,
            currentPlayingVideo: null,
            isRequested: false,
            similarNewsList: [],
            initialSlide: 0,
            likeCount: 0,
        };
        this.like = React.createRef();
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.toggleCurrentPlayingVideo = this.toggleCurrentPlayingVideo.bind(this);
        this.toggleNewsCategory = this.toggleNewsCategory.bind(this);
    }

    async componentDidMount() {
        const { translation } = this.props;
        const newsId = this.props.location && this.props.location.state && this.props.location.state.newsId;
        const slug = this.props.match.params.slug;
        const id = newsId || slug;
        let newsIsExist = true;
        id && await this.props.GetWebNewsById(id).catch(() => {
            newsIsExist = false;
            history.push({
                pathname: createUrl('not-found'),
                state: {
                    noDataMessage: (translation && translation['news-notFound-text']) || ''
                }
            })
        });


        if (newsIsExist) {
            let getNews = this.props.newsDetails.type === NEWS_MEDIA_TYPES.MAIN;
            let limit = getNews ? 5 : 4;
            let type = getNews ? NEWS_MEDIA_TYPES.MAIN : NEWS_MEDIA_TYPES.VIDEO;


            //this.getLikeCount();
            await this.props.GetAllNewsWeb({ limit, type });
            const similarNewsList = getNews ?
                this.props.newsList && this.props.newsList.itemsList && this.props.newsList.itemsList.filter(news =>
                    news.id !== this.props.newsDetails.id
                ) :
                this.props.videosList && this.props.videosList.itemsList && this.props.videosList.itemsList.filter(news =>
                    news.id !== this.props.newsDetails.id
                );
            this.setState({
                isRequested: true,
                similarNewsList: similarNewsList && similarNewsList.slice(0, getNews ? 4 : 3)
            })
        }
        //console.log('mount', window.FB)
        //window.FB && window.FB.XFBML.parse();

    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedLanguage, translation } = this.props;

        const newsId = this.props.location && this.props.location.state && this.props.location.state.newsId;
        const slug = this.props.match.params.slug;

        if (((newsId && prevProps.newsDetails && prevProps.newsDetails.id && prevProps.newsDetails.id !== newsId)
            || (selectedLanguage.code !== prevProps.selectedLanguage.code))
            && this.state.isRequested) {
            await this.setState({
                isRequested: false,
            });

            let newsIsExist = true;


            const id = newsId || slug;
            id && await this.props.GetWebNewsById(id).catch(() => {
                newsIsExist = false;
                history.push({
                    pathname: createUrl('not-found'),
                    state: {
                        noDataMessage: (translation && translation['news-notFound-text']) || ''
                    }
                })
            });
            if (selectedLanguage.code !== prevProps.selectedLanguage?.code) {
                history.push(createUrl('news', this.props.newsDetails?.slug))
            }
            if (newsIsExist) {
                let getNews = this.props.newsDetails.type === NEWS_MEDIA_TYPES.MAIN;
                let limit = getNews ? 5 : 4;
                let type = getNews ? NEWS_MEDIA_TYPES.MAIN : NEWS_MEDIA_TYPES.VIDEO;
                // this.getLikeCount();
                await this.props.GetAllNewsWeb({ limit, type });


                const similarNewsList = getNews ?
                    this.props.newsList && this.props.newsList.itemsList && this.props.newsList.itemsList.filter(news =>
                        news.id !== this.props.newsDetails.id
                    ) :
                    this.props.videosList && this.props.videosList.itemsList && this.props.videosList.itemsList.filter(news =>
                        news.id !== this.props.newsDetails.id
                    );
                this.setState({
                    isRequested: true,
                    similarNewsList: similarNewsList && similarNewsList.slice(0, getNews ? 4 : 3)
                })
            }
        }
        //console.log('update - fb', window.FB)
        //window.FB && window.FB.XFBML.parse();

    }

    componentWillUnmount() {
        this.props.ClearNewsDetails();
    }

    toggleCurrentPlayingVideo(id = null) {
        this.setState({
            currentPlayingVideo: id
        })
    }

    openModal(initialSlide) {
        this.setState({
            alertDialogOpen: true,
            initialSlide
        })
    }

    closeModal() {
        this.setState({
            alertDialogOpen: false,
            alertMode: ""
        })
    }

    toggleNewsCategory(id) {
        this.props.SetSelectedCategory(id);
    };

    getYoutubeId(url = '') {
        // eslint-disable-next-line no-useless-escape
        let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        let match = url.match(regExp);
        if (match && match[2] && match[2].length === 11) {
            return match[2];
        } else {
            return '';
        }
    }

    render() {

        const { alertDialogOpen, isRequested, similarNewsList, initialSlide } = this.state;
        const { newsDetails, selectedLanguage, translation } = this.props;
        // const slug = this.props.match.params.slug;
        const spinIcon = <Icon type="loading" style={{ fontSize: 50, color: "#7295fe" }} spin />;

        const momentLocales = moment.locales();
        const momentLocalCode = momentLocales && momentLocales.find(lg => lg.includes(selectedLanguage && selectedLanguage.code));
        const date = moment(newsDetails && newsDetails.date).locale(momentLocalCode ? momentLocalCode : '').format("MMMM DD, YYYY");

        const sliderIsInfinte = (newsDetails && newsDetails.mediaArray && newsDetails.mediaArray.length) > 5;
        const settings = {
            dots: false,
            dotsClass: "slick-dots slick-thumb",
            infinite: sliderIsInfinte,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            centerPadding: "40px 0 0",
            centerMode: false,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        centerPadding: "20px 0 0",
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        centerMode: true,
                        centerPadding: "40px 0 0",
                        slidesToShow: 1,
                    }
                }
            ]
        };

        // let shareUrl = `https://penitentiary.am${createUrl('news', slug)}`

        const videoId = newsDetails?.videoUrl && this.getYoutubeId(newsDetails?.videoUrl);
        // const videoImage = `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
        const videoImage = `https://img.youtube.com/vi/${videoId}/0.jpg`;

        return <div className="news-details">
            {newsDetails && <MetaTags title={newsDetails.title}
                image={newsDetails.videoUrl ? videoImage : generateMediaUrl(newsDetails.mediaMain && newsDetails.mediaMain.path)} />}
            {isRequested ?
                <>
                    {!!newsDetails && <>
                        <div className="news-header">
                            {
                                newsDetails.videoUrl ?
                                    <div className="video-clip">
                                        <div className="video-wrapper">
                                            <YouTubePlayer
                                                url={newsDetails.videoUrl}
                                                playing={newsDetails.id === this.state.currentPlayingVideo}
                                                width={'100%'}
                                                height={'100%'}
                                                className="video-youtube"
                                                // preload={true}
                                                onPlay={() => this.toggleCurrentPlayingVideo(newsDetails.id)}
                                                youtubeConfig={{
                                                    playerVars: {
                                                        showinfo: 0,
                                                        rel: 0,
                                                        modestbranding: 1,
                                                        controls: 1,
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    : <div className="main-img">
                                        <div className="img-content">
                                            <img
                                                src={generateMediaUrl(newsDetails.mediaMain && newsDetails.mediaMain.path)}
                                                alt={newsDetails.mediaMain ? newsDetails.mediaMain.altAttribute : ''} />
                                        </div>

                                    </div>
                            }

                            <div className="news-title-content">
                                <div className="news-title">{newsDetails.title}</div>
                                <div className="categories">
                                    {
                                        newsDetails.categories && newsDetails.categories.map((item) => {
                                            let title = item.title && item.title.toUpperCase();
                                            title = title && title.replace('ԵՒ', 'ԵՎ');
                                            return <Link to={createUrl('news')} key={item.id}>
                                                <button
                                                    onClick={() => this.toggleNewsCategory(item.id)}>{title}</button>
                                            </Link>
                                        })
                                    }
                                </div>


                            </div>
                        </div>
                        <div className="news-content ck-content-wrapper"
                            dangerouslySetInnerHTML={{ __html: newsDetails && newsDetails.description }} />
                        <div className="news-slider">
                            <Slider {...settings}>
                                {
                                    newsDetails.mediaArray && newsDetails.mediaArray.map((item, index) => {
                                        return <div key={item.id} onClick={() => {
                                            this.openModal(index)
                                        }}
                                            className={"slider-item"}>
                                            <img src={generateMediaUrl(item.path)} alt="img" />
                                        </div>
                                    })
                                }
                            </Slider>
                        </div>
                        <div className={'share-wrapper'}>
                            <div className="news-calendar">
                                <img src={require('../../assets/images/ic_calendar.svg')}
                                    alt="" />
                                {date}
                            </div>
                            {/*                      <div className="fb-like"
                                 data-href={shareUrl}
                                 data-width="300"
                                 data-layout="button"
                                 data-action="like"
                                 data-size="large"
                                 data-share="false"/>
      */}
                            {/* <FacebookProvider appId="1586514504887199">
                                <Like href={shareUrl}
                                      colorScheme="dark"
                                      ref={this.like}
                                      size={'large'}
                                      layout={'button_count'}
                                      share={true}/>
                            </FacebookProvider> */}

                            {/*<button type="button" className={'fb-btn like-button'} onClick={this.handleLike}>*/}
                            {/*    <div className={'like'} >*/}
                            {/*        <FacebookProvider appId="176283380373412">*/}
                            {/*            <Like href={shareUrl}*/}
                            {/*                  colorScheme="dark"*/}
                            {/*                  ref={this.like}*/}
                            {/*                  size={'large'}*/}
                            {/*                  layout={'button_count'}/>*/}
                            {/*        </FacebookProvider>*/}
                            {/*    </div>*/}
                            {/*    <img src={require('../../assets/images/icons/ic_fb_like.png')}/>*/}
                            {/*    <span>Like</span>*/}
                            {/*    <span>{likeCount}</span>*/}
                            {/*</button>*/}
                            {/*<button type="button" className={'fb-btn like-button'} onClick={this.handleShare}>*/}
                            {/*    <span>  Share</span>*/}
                            {/*</button>*/}


                        </div>
                        <div className="more-news">
                            <h2>{translation && translation['news-details-see-more']}</h2>
                            <div className="content">
                                {
                                    similarNewsList && similarNewsList.map((item) => {
                                        if (newsDetails.type === 'MAIN') {
                                            return <NewsCard news={item} key={item.id} />
                                        } else {
                                            return <VideoCard
                                                card={item}
                                                key={item.id}
                                                currentPlayingVideo={this.state.currentPlayingVideo}
                                                toggleCurrentPlayingVideo={this.toggleCurrentPlayingVideo}
                                            />
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </>
                    }
                </>

                :
                <div className="news-spin">{spinIcon}</div>
            }
            <SliderModal
                buttons={["Close"]}
                isOpen={alertDialogOpen}
                closeModal={this.closeModal}
                mediaArray={newsDetails && newsDetails.mediaArray}
                initialSlide={initialSlide}
            />
        </div>
    }
}

const mapDispatchToProps = {
    GetWebNewsById,
    GetAllNewsWeb,
    ClearNewsDetails,
    SetSelectedCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);

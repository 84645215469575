import {createSelector} from "reselect";
import {contactSelectorKeys} from "./selectorKeys";

const contact_S = state => state.contact;

const creatorFn = selectorKey => {
    return createSelector(contact_S, data => data[selectorKey])
};

export const contactSelectors = state => {
    const sData = {};

    contactSelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../config/history";

// Import utils
import { mapStateToProps } from "../../admin/redux/mapStateToProps";
import { ClearMemberById, GetMemberById, GetMembers } from "../redux/actions";
import { generateMediaUrl } from "../utils/generateMediaUrl";

// Import components
import PageHeader from "../components/dataDisplay/PageHeader";
// Import styles
import "../../assets/styles/pages/memberDetails.scss"
import { createUrl } from "../utils/createUrl";
// import { LANGUAGES, WEBPAGES } from "../constants/constants";
import { WEBPAGES } from "../constants/constants";
import MetaTags from "../components/MetaTags";

class MemberDetails extends Component {
    constructor(props) {
        super();
        this.state = {
            memberIsChief: props.location && this._checkIfDirector(props.location.pathname),
        }
    }

    // _checkIfDirector(pathname){
    //     const directorPages = WEBPAGES.director;
    //     let result = false;
    //     LANGUAGES.forEach(lg => {
    //         if ( pathname.includes(`/${directorPages[lg]}`) ) {
    //             result = true;
    //         }
    //     });
    //     return result;
    // }

    _checkIfDirector(pathname) {
        const directorPage = WEBPAGES.director;
        return pathname.includes(`/${directorPage}`);
    }

    async componentDidMount() {
        const { memberIsChief } = this.state;

        if (memberIsChief) {
            this.props.GetMembers(true);
        } else {
            const id = this.props.match && this.props.match.params && this.props.match.params.id;
            if (id) {
                this.props.GetMemberById(id);
            } else {
                history.push(createUrl('members'))
            }
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const { memberIsChief } = this.state;

        const { selectedLanguage } = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            if (memberIsChief) {
                this.props.GetMembers(true);
                history.push(createUrl('director'))
            } else {
                const id = this.props.match && this.props.match.params && this.props.match.params.id;
                if (id) {
                    this.props.GetMemberById(id);
                    history.push(createUrl('members', id))
                } else {
                    history.push(createUrl('members'))
                }
            }
        } else if (prevProps.location && this.props.location &&
            prevProps.location.pathname !== this.props.location.pathname) {
            const newMemberIsChief = this.props.location && this._checkIfDirector(this.props.location.pathname);
            this.setState({
                memberIsChief: newMemberIsChief
            })
            if (newMemberIsChief) {
                this.props.GetMembers(true);
            } else {
                const id = this.props.match && this.props.match.params && this.props.match.params.id;
                if (id) {
                    this.props.GetMemberById(id);
                } else {
                    history.push(createUrl('members'))
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.ClearMemberById();
    }

    render() {
        const { memberIsChief } = this.state;
        const { translation, chief, memberData } = this.props;
        const pageHeader = {
            title: memberIsChief ? translation && translation['director-page-title'] : '',
            slider: false,
        };
        const data = memberIsChief ? chief : memberData;

        return <div className="member-details">
            {!!data && <>
                <PageHeader pageHeader={pageHeader} />
                <MetaTags title={`${data.firstName} ${data.lastName}`}
                    image={data.memberMedia && generateMediaUrl(data.memberMedia && data.memberMedia.path)} />
                <div className="member-header">
                    <div className="img-wrapper">
                        <div className="img-content">
                            <img
                                src={generateMediaUrl(data.memberMedia && data.memberMedia.path)}
                                alt={data.memberMedia ? data.memberMedia.altAttribute : ''} />
                        </div>
                    </div>
                    <div className="wrapper">
                        <h1 className="member-title">{data.firstName} {data.lastName}</h1>
                        <div className="member-profession">{data.profession}</div>
                    </div>
                </div>
                <div className="member-content ck-content-wrapper"
                    dangerouslySetInnerHTML={{ __html: data.biography }} />
            </>}
        </div>
    }
}

const mapDispatchToProps = {
    GetMembers,
    GetMemberById,
    ClearMemberById
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetails);

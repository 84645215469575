//import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/pages/services.scss';

// Import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {GetInstitutionsWeb} from "../redux/actions";

//import components
import PageHeader from "../components/dataDisplay/PageHeader";
import InstitutionCard from "../components/InstitutionCard";
import { createUrl } from "../utils/createUrl";
import { history } from "../config/history";
import MetaTags from "../components/MetaTags";


class Institutions extends Component {

    componentDidMount() {
        this.props.GetInstitutionsWeb();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetInstitutionsWeb();
            history.push(createUrl('institutions'))
        }
    }

    render() {
        const {institutions, translation} = this.props;
        const pageHeader = {
            title: translation && translation['institutions-page-title'],
            slider: false,
        };
        return <div className="services">
            <MetaTags title={translation && translation['institutions-page-title']}/>
            <PageHeader pageHeader={pageHeader}/>

            <div className="services-content">
                {
                    institutions && institutions.map((item) => {
                        return <InstitutionCard data={item} key={item.id}/>
                    })
                }
            </div>
        </div>
    }
}

const mapDispatchToProps = {
    GetInstitutionsWeb,
};
export default connect(mapStateToProps, mapDispatchToProps)(Institutions);

import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../config/history";

//import styles
import '../../assets/styles/pages/customPage.scss';

//import components
import PageHeader from "../components/dataDisplay/PageHeader";

//import utils
import { mapStateToProps } from "../../admin/redux/mapStateToProps";
import { ClearCustomPage, ClearMetaData, GetCustomPage } from "../redux/actions";
import { generateMediaUrl } from "../utils/generateMediaUrl";
import { SliderModal } from "../components/dataDisplay/SliderModal";
import { createUrl } from "../utils/createUrl";
import MetaTags from "../components/MetaTags";

class CustomPage extends Component {
    constructor(){
        super();
        this.state = {
            initialSlide: 0,
            alertDialogOpen: false,
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    async componentDidMount(){
        const pageSlug = this.props.match && this.props.match.params && this.props.match.params.slug;

        this.props.GetCustomPage(pageSlug).then((pageData) => {
            const pageSlug = this.props.match && this.props.match.params && this.props.match.params.slug;
            if ( pageSlug !== pageData.pageUrl ) {
                pageData && pageData.pageUrl && history.push(createUrl(pageData.pageUrl))
            }
        }).catch(() => {
            history.push(createUrl('not-found'))
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        const { selectedLanguage } = this.props;
        const prevSlug = prevProps.match && prevProps.match.params && prevProps.match.params.slug;
        const pageSlug = this.props.match && this.props.match.params && this.props.match.params.slug;
        const { customPageData } = this.props;
        if ( prevSlug !== pageSlug && pageSlug !== customPageData?.pageUrl ) {
            this.props.GetCustomPage(pageSlug).then((pageData) => {
                if ( pageSlug !== pageData.pageUrl ) {
                    pageData && pageData.pageUrl && history.push(createUrl(pageData.pageUrl))
                }
            }).catch(() => {
                history.push(createUrl('not-found'))
            });
        }

        if ( selectedLanguage.code !== prevProps.selectedLanguage.code ) {
            this.props.GetCustomPage(pageSlug).then((pageData) => {
                        pageData && pageData.pageUrl && history.push(createUrl(pageData.pageUrl))
                    }
            ).catch(() => {
                history.push(createUrl('not-found'))
            })
        }


    }

    componentWillUnmount() {
        this.props.ClearMetaData();
        this.props.ClearCustomPage();
    }

    openModal(initialSlide) {
        this.setState({
            alertDialogOpen: true,
            initialSlide
        })
    }

    closeModal() {
        this.setState({
            alertDialogOpen: false,
            alertMode: ""
        })
    }

    render() {
        const {initialSlide, alertDialogOpen} = this.state;
        const {customPageData} = this.props;
        const pageHeader = {
            title: customPageData && customPageData.title,
            slider: false,
            filter: false,
        };
        return <div className="custom-page">
            <PageHeader pageHeader={pageHeader}/>

            {!!customPageData && <div className="page-content">
                <MetaTags title={ customPageData && customPageData.title}
                image={generateMediaUrl(customPageData.mediaMain && customPageData.mediaMain.path)}/>
                {!!customPageData.mediaMain && <div className={'image-wrapper'}>
                    <img
                        src={generateMediaUrl(customPageData.mediaMain && customPageData.mediaMain.path)}
                        alt={customPageData.mediaMain ? customPageData.mediaMain.altAttribute : ""}/>
                </div>}
                <div className={'editor-data ck-content-wrapper'}
                     dangerouslySetInnerHTML={{__html: customPageData.content}}>

                </div>
            </div>}
            <div className="media-wrapper">
                {
                    customPageData.mediaArray && customPageData.mediaArray.length !== 0
                    && customPageData.mediaArray.map((item, index) => {
                        return <div className="media-item" key={item.id} onClick={() => {
                            this.openModal(index)
                        }}>
                            <div className="image">
                                <img src={generateMediaUrl(item.path && item.path)}
                                     alt={item.altAttribute ? item.altAttribute : ""}/>
                            </div>
                        </div>
                    })
                }
            </div>
            <SliderModal
                buttons={["Close"]}
                isOpen={alertDialogOpen}
                closeModal={this.closeModal}
                mediaArray={customPageData.mediaArray && customPageData.mediaArray.length !== 0 && customPageData.mediaArray}
                initialSlide={initialSlide}
            />
        </div>
    }
}

const mapDispatchToProps = {
    GetCustomPage,
    ClearCustomPage,
    ClearMetaData
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPage);

import {authConsts, newsConsts} from "../constants";

const initialState = {
    newsList: {
        itemsList: [],
        hasMore: true,
        categoryId: null,
        type:null,
        text:'',
    },
    newsListForSlider: {
        itemsList: [],
        hasMore: true,
        text: ''
    },
    singleNews: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case newsConsts.GET_ALL_NEWS:
            const newNewsList = {
                itemsList: action.payload.reset ? action.payload.data : [...state.newsList.itemsList, ...action.payload.data],
                categoryId: action.payload.categoryId,
                type:action.payload.type,
                text:action.payload.text,
                hasMore: action.payload.hasMore
            };
            return {
                ...state,
                newsList: newNewsList
            };
        case newsConsts.GET_NEWS_FOR_SLIDER:
            const newSliderNewsList = {
                itemsList: action.payload.reset ? action.payload.data : [...state.newsListForSlider.itemsList, ...action.payload.data],
                text: action.payload.text,
                hasMore: action.payload.hasMore
            };
            return {
                ...state,
                newsListForSlider: newSliderNewsList
            };
        case newsConsts.DELETE_NEWS_BY_ID:
            return {
                ...state,
                newsList: {
                    itemsCount: state.newsList.itemsCount - 1,
                    itemsList: state.newsList.itemsList.filter(project => project.id !== action.payload)
                }
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

/* eslint-disable react/jsx-no-target-blank */
// Import packages
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { mapStateToProps } from "../../admin/redux/mapStateToProps";
import { GetCategoriesWeb, GetMenuWeb, GetContactsWeb, SetSelectedCategory } from "../redux/actions";
// Import styles
import "../../assets/styles/layoutFooter.scss";
import { createUrl } from "../utils/createUrl";

class Footer extends React.PureComponent {
    constructor() {
        super();
        this.toggleNewsCategory = this.toggleNewsCategory.bind(this)
    }
    toggleNewsCategory(id) {
        this.props.SetSelectedCategory(id);
    };
    componentDidMount() {
        //        this.props.GetMenuWeb();
        this.props.GetCategoriesWeb();
        this.props.GetContactsWeb();
        this.props.SetSelectedCategory();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedLanguage } = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetCategoriesWeb();
            this.props.GetContactsWeb();
        }
    }
    render() {
        const { menuList, categories, contacts, translation, selectedLanguage } = this.props;

        return <footer className="layout-footer">
            <div className="top">
                <div className="content">
                    <div className="img">
                        <img src={require('../../assets/images/header/ced_logo.png')} alt="" />
                    </div>
                    <div className="content-inner">
                        <ul className="footer-home-page">
                            {
                                menuList && menuList.slice(0, 5).map((item) => {
                                    return <li key={item.id}>
                                        <Link to={createUrl(item.url)}>{item.title}</Link>
                                    </li>
                                })
                            }
                        </ul>
                        <ul className="footer-news">
                            <Link to={createUrl('news')}><li>{translation && translation['footer-news-categories-title']}</li></Link>

                            {
                                categories && categories.slice(0, 4).map((item) => {
                                    return <li key={item.id} onClick={() => this.toggleNewsCategory(item.id)}>
                                        <Link to={createUrl('news')}>{item.title}</Link>
                                    </li>
                                })
                            }
                        </ul>
                        <ul>
                            <Link to={createUrl('contact')}><li>{translation && translation['footer-contacts-title']}</li></Link>
                            <li><span className="footer-span">{translation && translation['footer-contacts-telephone']} </span> {contacts && contacts.hotLinePhone}</li>
                            <li><span className="footer-span">{selectedLanguage?.code === "hy" ? `Ընդհանուր բաժին (փաստաթղթաշրջանառություն)՝` : "Общий отдел:"} </span> 060370524</li>
                            <li><span className="footer-span">{selectedLanguage?.code === "hy" ? `Կադրերի և անձնակազմի հետ տարվող աշխատանքների բաժին՝` : "Отдел кадров и работы с личным составом։"} </span> 060373755</li>
                            <li><span className="footer-span">{selectedLanguage?.code === "hy" ? `Հանրային կապերի բաժին՝` : "Отдел общественных связей։"} </span> 060473698</li>
                            <li><span className="footer-span">{translation && translation['footer-contacts-voice-portal']} </span> {contacts && contacts.voicePortal}</li>

                            <li><span className="footer-span">{translation && translation['footer-contacts-email']} </span> {contacts && contacts.email}</li>
                            <li><span className="footer-span">{translation && translation['footer-contacts-address']} </span> {contacts && contacts.address}</li>
                        </ul>
                    </div>
                    <div className="social">
                        <div>
                            <a href={contacts && contacts.fbUrl} target="_blank"><img src={require('../../assets/images/ic_facebook.svg')} alt="" /></a>
                        </div>
                        <div>
                            <a href={contacts && contacts.youtubeUrl} target="_blank"><img src={require('../../assets/images/ic_youtube.svg')} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="content">
                    <div className="left">{translation && translation['footer-description']}</div>
                    <div className="right">{translation && translation['footer-developed-by']}
                        <a href="https://www.studio-one.am/" target="_blank"><img src={require('../../assets/images/studi1_logo.svg')} alt="" /></a>
                    </div>
                </div>
            </div>
        </footer>
    }
}
const mapDispatchToProps = {
    GetMenuWeb,
    GetCategoriesWeb,
    GetContactsWeb,
    SetSelectedCategory,
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);

// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import Slider from "react-slick";

// Import utils
import {mapStateToProps} from "../../../admin/redux/mapStateToProps";
import {GetCategoriesWeb, SetSelectedCategory} from "../../redux/actions";

// Import styles
import '../../../assets/styles/dataDisplay/categorySlider.scss';

class CategorySlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_category: null,
        };

        this.toggleSelectedCategory = this.toggleSelectedCategory.bind(this);
        this.categoryRef = React.createRef();
        this.lastChild = React.createRef();
    }

    componentDidMount() {

        this.props.GetCategoriesWeb();

        if (this.props.selectedCategoryId) {
            this.setState({
                selected_category: this.props.selectedCategoryId
            });
            this.props.SetSelectedCategory(null);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.selectedCategoryId && this.props.selectedCategoryId) {
            this.toggleSelectedCategory(this.props.selectedCategoryId);
            this.props.SetSelectedCategory(null);
        }
    }


    toggleSelectedCategory(id) {
        this.props.getNews(id);
        this.setState({
            selected_category: id
        });
    };

    getSlidesToShowCount() {
        const innerWidth = window.innerWidth;
        return innerWidth >= 1400 ? 8
            : (innerWidth >= 1100 ? 6
                : (innerWidth >= 800 ? 5
                    : (innerWidth >= 600 ? 4
                        : (innerWidth >= 350 ? 3 : 2))))
    }

    nextSlide() {
        this.categoryRef.slickNext();
    }

    prevSlide() {
        this.categoryRef.slickPrev()
    }

    render() {
        const {categories, translation} = this.props;

        const settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 8,
            slidesToScroll: 1,
            accessibility: true,
            initialSlide: 0,
            centerPadding: "100px 0 0",
            // swipe: this.state.swipe,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        centerPadding: " 80px 0 0",
                        slidesToShow: 6,
                    }
                }, {
                    breakpoint: 1100,
                    settings: {
                        centerPadding: " 80px 0 0",
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        centerPadding: "100px 0 0",
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        centerPadding: " 60px 0 0",
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 350,
                    settings: {
                        centerPadding: "60px 0 0",
                        slidesToShow: 2,
                    }
                },
            ]
        };

        return <div className="category-slider">
            <Slider {...settings} ref={slide => (this.categoryRef = slide)}>
                <div
                    className={`category-item ${!this.state.selected_category ? "selected" : ''}`}
                    style={{width: "auto"}}
                    onClick={() => this.toggleSelectedCategory(null)}>
                    {translation && translation['all-categories-button-text']}
                </div>

                {categories && categories.map((item, index) => {
                    return categories.length - 1 === index ?
                        <div key={item.id} ref={(el) => this.lastChild = el}
                             className={`category-item ${(item.id === this.state.selected_category) ? "selected" : ''}`}
                             style={{width: "auto"}}
                             onClick={() => this.toggleSelectedCategory(item.id)}>
                            {item.title}
                        </div> :
                        <div key={item.id}
                             className={`category-item ${(item.id === this.state.selected_category) ? "selected" : ''}`}
                             style={{width: "auto"}}
                             onClick={() => this.toggleSelectedCategory(item.id)}>
                            {item.title}
                        </div>
                })}


            </Slider>
            {this.getSlidesToShowCount() < categories && categories.length + 1 &&
            <div className={'category-arrows'}>
                <div className={'prev-btn'} onClick={() => this.prevSlide()}>
                    <img className="slider-btn-img"
                         src={require('../../../assets/images/ic_black_left_arrow.svg')}
                         alt="icon"/>
                </div>
                <div className={'next-btn'} onClick={() => this.nextSlide()}>
                    <img className="slider-btn-img"
                         src={require('../../../assets/images/ic_black_right_arrow.svg')}
                         alt="icon"/>
                </div>
            </div>
            }
        </div>
    }
}

const mapDispatchToProps = {
    GetCategoriesWeb,
    SetSelectedCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySlider);

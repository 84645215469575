import {createSelector} from "reselect";
import {menuSelectorKeys} from "./selectorKeys";

const menu_S = state => state.menu;

const creatorFn = selectorKey => {
    return createSelector(menu_S, data => data[selectorKey])
};

export const menuSelectors = state => {
    const sData = {};

    menuSelectorKeys.forEach(key => {
        sData[key] = creatorFn(key)(state)
    });

    return sData;
};
import {authConsts, fileUploadConsts} from "../constants";

const initialState = {
    tempFileUrl: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case fileUploadConsts.UPLOAD_FILES:
            return {
                ...state,
                tempFileUrl: action.payload
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

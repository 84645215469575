//import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/pages/services.scss';

// Import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {GetMembers} from "../redux/actions";

//import components
import PageHeader from "../components/dataDisplay/PageHeader";
import MemberCard from "../components/MemberCard";
import { history } from "../config/history";
import { createUrl } from "../utils/createUrl";
import MetaTags from "../components/MetaTags";

class Members extends Component {

    componentDidMount() {
        this.props.GetMembers();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        if(selectedLanguage.code !== prevProps.selectedLanguage.code){
            history.push(createUrl('members'))
            this.props.GetMembers();
        }
    }
    render() {
        const {members, translation} = this.props;
        const pageHeader = {
            title: translation && translation['members-page-title'],
            slider: false,
        };
        return <div className="services">
            <PageHeader pageHeader={pageHeader}/>
            <MetaTags title={translation && translation['members-page-title']}/>
            <div className="services-content">
                {
                    members && Array.isArray(members) && members.slice(1).map((item) => {
                        return <MemberCard data={item} key={item.id}/>
                    })
                }
            </div>
        </div>
    }
}

const mapDispatchToProps = {
    GetMembers
};
export default connect(mapStateToProps, mapDispatchToProps)(Members);

// Import packages
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import 'moment/locale/hy-am';
import 'moment/locale/ru';

// Import components
import LayoutNavigation from "../components/LayoutNavigation";
import Footer from "../components/Footer";
import MetaTags from "../components/MetaTags";
// Import utils
import { mapStateToProps } from "../../admin/redux/mapStateToProps";
import { GetTranslations, GetLanguages, ChangeLanguage } from "../redux/actions";
import { LANGUAGES, LANGUAGES_OBJ } from "../constants/constants";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        const urlLg = window.location.pathname.split('/')?.[1];
        let languageCode = LANGUAGES.includes(urlLg) ? LANGUAGES_OBJ[urlLg].code : 'hy';

        const { languages } = props;

        // console.log('constructor', props.selectedLanguage.code, languageCode);
        if (props.selectedLanguage.code !== languageCode) {
            const newLg = languages.find(lg => lg.code === languageCode) || LANGUAGES_OBJ[languageCode];
            if (newLg) {
                // console.log('constructor change language', newLg.code)
                props.ChangeLanguage(newLg)
            }
        }

    }

    componentDidMount() {
        this.props.GetTranslations().then(() => {
            this.props.GetLanguages();
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location && this.props.location) {
            if (prevProps.location.pathname !== this.props.location.pathname) {
                window.scroll(0, 0);
            }
        }
    }

    render() {
        const { contactData, menuList, children } = this.props;
        return <div className=" custom-scrollbar-y">
            <MetaTags />
            <LayoutNavigation contactData={contactData}
                menuList={menuList} />
            {children}
            <Footer />
        </div>
    }
}

const mapDispatchToProps = {
    GetTranslations,
    GetLanguages,
    ChangeLanguage
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));

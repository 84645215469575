// Import packages
import React, {Component} from "react";

// Import components
import CategorySlider from "./CategorySlider";
import CategoryFilter from "./CategoryFilter";

// Import utils

// Import styles
import '../../../assets/styles/dataDisplay/pageHeader.scss';

export default class PageHeader extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        const {pageHeader, categoryList, selectedCategory,toggleSelectedCategory} = this.props;

        return <div className="page-header">
            <h1 className="category-title"> {pageHeader.title} </h1>
            {pageHeader.slider && <CategorySlider getNews={this.props.getNews}/>}
            {pageHeader.filter && <CategoryFilter categoryList={categoryList}
                                                  selectedCategory={selectedCategory}
                                                  toggleSelectedCategory={toggleSelectedCategory}/>}
        </div>
    }
}

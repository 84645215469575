export * from "./authConsts";
export * from "./userConsts";
export * from "./categoriesConsts";
export * from "./contactConsts";
export * from "./fileUploadConsts";
export * from "./galleryConsts";
export * from "./menuConsts";
export * from "./newsConsts";
export * from "./sliderConsts";
export * from "./teamConsts";
export * from "./staticTextConsts";
export * from "./utilConsts";
export * from "./rolesConsts";
export * from "./languagesConsts";
export * from "./pagesConsts";
export * from "./institutionConsts";


// Import packages
import React from "react";

// Import styles
import "../../../assets/styles/dataDisplay/categoryFilter.scss"

export default function CategoryFilter(props) {
    const {categoryList, selectedCategory} = props;
    return <div className="category-filter">
        {
            categoryList && categoryList.map((item) => {
                return <div key={item.id}
                            className={`category-item ${(item.id === selectedCategory) ? "selected" : ''}`}
                            onClick={() => props.toggleSelectedCategory(item.id)}>
                    {item.title}
                </div>
            })
        }
    </div>
}

import React, { PureComponent } from 'react';
import {
    withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from "../../admin/redux/mapStateToProps";
import { Helmet } from "react-helmet";


class MetaTags extends PureComponent {
    render() {
        const { translation } = this.props;
        const defaultTitle = (translation && translation['default-title']) || "ՀՀ ԱՆ Քրեակատարողական ծառայություն";
        const defaultDescription = (translation && translation['default-description']) || '';

        const { title, description, image, url } = this.props;
        const _title = title ? (`${title} | ${defaultTitle}`) : defaultTitle;
        const _description = description || defaultDescription;

        // let _image = 'https://static.penitentiary.am/images/ced-logo.png';
        // if (image) {
        //     _image = image;
        // }
        const logo = "https://static.penitentiary.am/images/ced-logo.png"
        const _image = image || logo;
        const _url = url || window.location.href;
        const { origin } = window.location;
        const BASE_URL = origin;

        return (<Helmet>
            <title>{_title}</title>
            <meta name="description" content={_description} />

            <meta property="og:image" content={_image} />
            <meta property="og:image:secure_url" content={_image} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="image" />

            <meta property="og:title" content={_title} />
            <meta property="og:site_name" content='penitentiary.am' />
            <meta property="og:description" content={_description} />

            <meta property="og:url" content={_url} />
            <meta property="og:type" content='website' />
            <meta property="fb:app_id" content="1586514504887199" />
            <link rel="manifest" href={`${BASE_URL}/manifest.json`} />
            <link rel="shortcut icon" href={`${BASE_URL}/static/favicon.ico`} />
        </Helmet>
        )
    }
}

export default connect(mapStateToProps)(withRouter(MetaTags));

import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import user from "./user";
import role from "./role";
import news from "./news";
import utils from "./utils";
import language from './language';
import gallery from "./gallery";
import menu from "./menu";
import categories from "./categories";
import page from "./page";
import staticText from "./staticText";
import files from "./files";
import contact from "./contact";
import member from "./team";
import slider from "./slider";
import institutions from "./institutions";

import web from "../../../app/redux/reducer"

export default combineReducers({
    auth,
    role,
    user,
    utils,
    news,
    language,
    gallery,
    menu,
    categories,
    page,
    files,
    contact,
    member,
    slider,
    staticText,
    institutions,

    web,
});
import {languageConsts, authConsts} from "../constants";

const initialState = {
    languages: [],
    mainLanguage: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case languageConsts.GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
                mainLanguage: action.payload.find(lg => lg.isMain).id
            };
        case languageConsts.NEW_LANGUAGE:
            return {
                ...state,
                languages: [...state.languages, action.payload]
            };
        case languageConsts.UPDATE_LANGUAGE:
            return {
                ...state,
                languages: state.languages.map(language =>
                    language.id === action.payload.id ? action.payload : language
                )
            };
        case languageConsts.DELETE_LANGUAGE:
            return {
                ...state,
                languages: state.languages.filter(role => role.id !== action.payload)
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

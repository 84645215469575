import {authConsts,  institutionConsts} from "../constants";

const initialState = {
    institutionList: [],
    singleInstitution: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case institutionConsts.GET_INSTITUTIONS_LIST:
            return {
                ...state,
                institutionList: action.payload
            };
        case institutionConsts.UPDATE_INSTITUTION:
            return {
                institutionList: state.institutionList.map(item =>
                    item.id === action.payload.id ? action.payload : item
                )
            };
        case institutionConsts.DELETE_INSTITUTION_BY_ID:
            return {
                ...state,
                institutionList: state.institutionList.filter(item => item.id !== action.payload)
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {history} from "../config/history";
// Import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";

// Import styles
import "../../assets/styles/notFound.scss";
import { createUrl } from "../utils/createUrl";

class NotFound extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            history.push(createUrl('not-found'))
        }
    }
    render() {
        const {leftSideMenuOpen, translation} = this.props;
        const inCMS = history.location&&  history.location.pathname&&  history.location.pathname.includes('admin');
        const noDataMessage = this.props.location && this.props.location.state && this.props.location.state.noDataMessage;
        return <div className={`not-found-page ${leftSideMenuOpen ? "size-sm" : ""}`}>
            <div className="inner-content">

                <img src={require('../../assets/images/page_not_found.svg')}
                     alt=""/>
                {
                    !inCMS && <span className="text-message">{noDataMessage? noDataMessage: (translation && translation["page-notFound-text"])}</span>
                }
            </div>
        </div>
    }
}

export default connect(mapStateToProps)(withRouter(NotFound));

export const routPath = {
    NEWS: "/news",
    VIDEOS: "/videos"
};

// export const WEBPAGES = {
//     news:    { hy: 'նորություններ', ru: 'новости', en: 'news' },
//     videos: { hy: 'տեսանյութեր', ru: 'видео', en: 'videos' },
//     institutions: { hy: 'hիմնարկներ', ru: 'учреждения', en: 'institutions' },
//     members: { hy: 'ղեկավարություն', ru: 'члены', en: 'members' },
//     director: { hy: 'տնօրեն', ru: 'директор', en: 'director' },
//     contact: { hy: 'կապ', ru: 'контакт', en: 'contact' },
//     vacancies: { hy: 'աշխատատեղեր', ru: 'вакансии', en: 'vacancies' },
//     search: { hy: 'որոնում', ru: 'поиск ', en: 'search' },
//     'not-found': { hy: 'գտնված-չէ', ru: 'не-найдено', en: 'not-found' },
// }

export const WEBPAGES = {
    news: 'news',
    videos: 'videos',
    institutions: 'institutions',
    members: 'members',
    director: 'director',
    contact: 'contact',
    vacancies: 'vacancies',
    search: 'search',
    'not-found': 'not-found',
}

export const LANGUAGES = ['hy', 'ru', 'en']

export const LANGUAGES_OBJ = {
    hy: {
        code: "hy",
        name: "ՀԱՅ",
        isMain: true,
    },
    ru: {
        code: "ru",
        name: "РУС",
        isMain: false,
    },
    en: {
        code: "en",
        name: "ENG",
        isMain: false,
    }
}

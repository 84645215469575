import {createStore, compose, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import Reducer from './reducer';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
};

let store;

const persistedReducer = persistReducer(persistConfig, Reducer);

store = createStore(persistedReducer,
    compose(applyMiddleware(thunk))
);

let persistor = persistStore(store);

export {store, persistor};
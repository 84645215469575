import React, { Component } from "react";
import { connect } from "react-redux";
import "../../assets/styles/pages/institutionDetails.scss"

import { generateMediaUrl } from "../utils/generateMediaUrl";
import { ClearInstitutionById, GetInstitutionById } from "../redux/actions";
import { mapStateToProps } from "../../admin/redux/mapStateToProps";
import { history } from "../config/history";
import { createUrl } from "../utils/createUrl";
import MetaTags from "../components/MetaTags";

class InstitutionDetails extends Component {

    async componentDidMount(){
        const id = this.props.match && this.props.match.params && this.props.match.params.id;
        if ( id ) {
            this.props.GetInstitutionById(id);
        } else {
            history.push('/institutions');
        }
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            const id = this.props.match && this.props.match.params && this.props.match.params.id;
            this.props.GetInstitutionById(id);
            history.push(createUrl('institutions', id))
        }
    }
    componentWillUnmount() {
        this.props.ClearInstitutionById();
    }

    render() {
        const {institutionData} = this.props;
        return <div className="institution-details">
            {institutionData && <MetaTags title={institutionData.title ? institutionData.title : ''}
                       image={generateMediaUrl(institutionData.institutionMedia && institutionData.institutionMedia.path)}/>}
            <div className="institution-header">
                <div className="img-content">
                    <div className="img-wrapper">
                        {institutionData && institutionData.institutionMedia && institutionData.institutionMedia.path &&
                        <img src={generateMediaUrl(institutionData.institutionMedia.path)}
                             alt={institutionData.institutionMedia ? institutionData.institutionMedia.altAttribute : ''}/>}
                    </div>
                </div>
                <div className="wrapper">
                    <h1 className="institution-title">{institutionData && institutionData.title ? institutionData.title : ''}</h1>
                </div>
            </div>
            {institutionData && institutionData.description && <div className="institution-content ck-content-wrapper"
                                                                    dangerouslySetInnerHTML={{__html: institutionData.description}}></div>}
        </div>
    }
}

const mapDispatchToProps = {
    GetInstitutionById,
    ClearInstitutionById
};
export default connect(mapStateToProps, mapDispatchToProps)(InstitutionDetails);

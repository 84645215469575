// Import packages
import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import utils
import { generateMediaUrl } from "../utils/generateMediaUrl";

// Import styles
import '../../assets/styles/institutionCard.scss';
import { createUrl } from "../utils/createUrl";


export default class MemberCard extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props)
    }

    render() {
        const {data} = this.props;

        return <div className="institution-card">
            {data && <div className="institution-card-img">
                <div className="img-wrapper">
                    <img src={generateMediaUrl(data.institutionMedia ? data.institutionMedia.path : '')}
                         alt={data.institutionMedia ? data.institutionMedia.altAttribute : ''}/>
                </div>
            </div>
            }

            {data && <div className="institution-card-content">
                <Link to={{
                    pathname: createUrl('institutions', data?.id),
                }}>
                    <div className="institution-card-name">{data.title}</div>
                </Link>

                <div className="institution-card-description" dangerouslySetInnerHTML={{__html: data.subtitle}}></div>
            </div>}
        </div>
    }
}

/* eslint-disable jsx-a11y/alt-text */
//import packages
import React, { Component } from "react";
import { connect } from 'react-redux';
// import ReCAPTCHA from "react-google-recaptcha";

//import style
import "../../assets/styles/pages/contact.scss"

//import Components
import GoogleMapReact from 'google-map-react';
import { Icon, Spin } from "antd";
import isEmail from "validator/lib/isEmail";
import { InputGroup } from "../../admin/components/dataDisplay/inputGroup";

//import utils
import { GetContactsWeb, SendContactsEmail } from "../redux/actions";
import { closeAlertModal, openAlertModal } from "../../admin/utils/commonFunctions";
import { mapStateToProps } from "../../admin/redux/mapStateToProps";
import { history } from "../config/history";
import { createUrl } from "../utils/createUrl";
import MetaTags from "../components/MetaTags";

const Marker = () => {
    return <div className={'marker'}>
        <img src={require('../../assets/images/marker.svg')} />
    </div>
};

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contactData: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                message: "",
            },
            errors: {
                firstName: false,
                lastName: false,
                email: false,
                phoneNumber: false,
                message: false,
            },
            recaptchaVerified: false,
            sendSuccess: false,
            showError: false,
            alertDialogOpen: false,
            alertMode: "",
            requestLoading: false
        };
        this.recaptcha = React.createRef();
        this.closeAlertModal = closeAlertModal.bind(this);
        this.openAlertModal = openAlertModal.bind(this);
        this.getInputValues = this.getInputValues.bind(this);
        this.verifyReCaptcha = this.verifyReCaptcha.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedLanguage } = this.props;
        if (selectedLanguage.code !== prevProps.selectedLanguage.code) {
            history.push(createUrl('contact'))
        }
    }
    getInputValues(e) {
        const { name, value } = e.target;
        const regex = /^[+\d]\d*$/;
        if (name === 'phoneNumber' && value && !regex.test(value)) {
            return;
        }
        this.setState({
            contactData: {
                ...this.state.contactData,
                [name]: value,
            },
        })
    }

    verifyReCaptcha(token) {
        this.setState({
            recaptchaVerified: !!token,
        })
    }

    async sendEmail() {
        await this.setState({
            requestLoading: true
        });
        // const { contactData, recaptchaVerified } = this.state;
        const { contactData } = this.state;
        const errors = {
            firstName: false,
            lastName: false,
            email: false,
            phoneNumber: false,
            message: false,
        };
        let allFieldsIsValid = true;
        Object.keys(contactData).forEach((key) => {
            if (!contactData[key]) {
                errors[key] = true;
                allFieldsIsValid = false;
            }
            if (key === 'email' && !isEmail(contactData[key])) {
                errors[key] = true;
                allFieldsIsValid = false;
            }
        })
        // if (allFieldsIsValid && recaptchaVerified) {
        if (allFieldsIsValid) {
            const reqData = {
                name: `${contactData.firstName} ${contactData.lastName}`,
                email: contactData.email,
                phone: contactData.phoneNumber,
                message: contactData.message
            };
            this.props.SendContactsEmail(reqData).then(() => {
                this.setState({
                    contactData: {
                        firstName: "",
                        lastName: "",
                        email: "",
                        phoneNumber: "",
                        message: "",
                    },
                    recaptchaVerified: false,
                    sendSuccess: true,
                });
                // this.recaptcha.current.reset(this.recaptcha.current.getWidgetId());
            }).catch(() => {
                this.setState({
                    showError: true
                })
            }).finally(() => {
                this.setState({
                    requestLoading: false
                })
                let new_this = this;
                setTimeout(function () {
                    new_this.setState({
                        sendSuccess: false
                    })
                }, 2000)
            })
        } else {
            this.setState({
                errors,
                requestLoading: false
            })
        }
    }

    render() {
        const spinIcon = <Icon type="loading" style={{ fontSize: 16, color: "#fff" }} spin />;
        const { contactData, requestLoading, errors, recaptchaVerified, showError, sendSuccess } = this.state;
        const { contacts, translation, selectedLanguage } = this.props;

        return <div className="contact-page">
            <MetaTags title={translation && translation['contact-page-title']} />
            <div className="contact-form">
                <div className="form-top">
                    <div className="form-input-left">
                        <InputGroup inputType="input"
                            type="text"
                            label={<>{translation && translation['contacts-name-input-label']}<i
                                className="input-required">*</i></>}
                            placeholder={translation && translation['contacts-name-input-placeholder']}
                            name="firstName"
                            value={contactData.firstName || ""}
                            error={errors.firstName}
                            onChange={this.getInputValues}
                        />
                        <InputGroup inputType="input"
                            type="email"
                            label={<>{translation && translation['contacts-email-input-label']}<i
                                className="input-required">*</i></>}
                            placeholder={translation && translation['contacts-email-input-placeholder']}
                            name="email"
                            value={contactData.email || ""}
                            error={errors.email}
                            onChange={this.getInputValues}
                        />
                    </div>
                    <div className="form-input-right">
                        <InputGroup inputType="input"
                            type="text"
                            label={<>{translation && translation['contacts-surname-input-label']}<i
                                className="input-required">*</i></>}
                            placeholder={translation && translation['contacts-surname-input-placeholder']}
                            name="lastName"
                            value={contactData.lastName || ""}
                            error={errors.lastName}
                            onChange={this.getInputValues}
                        />
                        <InputGroup inputType="input"
                            type="text"
                            label={<>{translation && translation['contacts-phone-number-input-label']}<i
                                className="input-required">*</i></>}
                            placeholder={translation && translation['contacts-phone-number-input-placeholder']}
                            name="phoneNumber"
                            value={contactData.phoneNumber || ""}
                            error={errors.phoneNumber}
                            onChange={this.getInputValues}
                        />
                    </div>
                </div>
                <div className="form-bottom">
                    <InputGroup inputType="textarea"
                        type="text"
                        label={<>{translation && translation['contacts-message-input-label']}<i
                            className="input-required">*</i></>}
                        placeholder={translation && translation['contacts-message-input-placeholder']}
                        name="message"
                        value={contactData.message || ""}
                        error={errors.message}
                        onChange={this.getInputValues}
                    />
                </div>
                <div className={`recaptcha-wrapper ${recaptchaVerified ? 'hide' : ''}`}>
                    <button className="link-button" onClick={this.sendEmail}>
                        <div className="flex-row">
                            {
                                !sendSuccess ? <span
                                    className="button-text">{translation && translation['contacts-send-message-button']}</span>
                                    : <Icon className='button-text-check' type="check" />
                            }
                            {requestLoading && <Spin indicator={spinIcon} />}

                        </div>
                    </button>
                    {/* <ReCAPTCHA
                        ref={this.recaptcha}
                        size={window.innerWidth < 350 ? 'compact' : 'normal'}
                        sitekey="6LerM9QUAAAAAETepZTYS27CzbIaju-2vEDt-szd"
                        onChange={this.verifyReCaptcha}
                        hl={selectedLanguage.code}
                    /> */}
                </div>
                {showError && <div
                    className="show-error">{translation && translation['contacts-error-message']}</div>}
            </div>
            <div className="contact-map">
                <div className="map-left">
                    <h3>{translation && translation['contacts-map-place-title']}</h3>
                    <div className="map">
                        <div style={{ height: '100%', width: '100%' }}>
                            {contacts && contacts.location && <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyACVcW7qBPdSi47lId2QL2HQwQzfusHwo0" }}
                                defaultCenter={{
                                    lat: contacts.location.latitude,
                                    lng: contacts.location.longitude
                                }}
                                defaultZoom={14}
                            >
                                <Marker
                                    lat={contacts.location && contacts.location.latitude}
                                    lng={contacts.location && contacts.location.longitude}
                                    zoom={11} />
                            </GoogleMapReact>}
                        </div>
                    </div>
                </div>
                <div className="map-info">
                    <div
                        className="info-text">{translation && translation['contacts-telephone']}
                        <span> {contacts && contacts.hotLinePhone}</span>
                        {/* <span> +374 (10) 442273</span> */}
                    </div>
                    <div className="info-text">
                        {selectedLanguage?.code === "hy"
                            ? `Ընդհանուր բաժին (փաստաթղթաշրջանառություն)՝`
                            : "Общий отдел:"}
                        <span> 060370524</span>
                    </div>
                    <div className="info-text">
                        {selectedLanguage?.code === "hy"
                            ? `Կադրերի և անձնակազմի հետ տարվող աշխատանքների բաժին՝`
                            : "Отдел кадров и работы с личным составом։"}
                        <span> 060373755</span>
                    </div>
                    <div className="info-text">
                        {selectedLanguage?.code === "hy"
                            ? `Հանրային կապերի բաժին՝`
                            : "Отдел общественных связей։"}
                        <span > 060473698</span>
                    </div>
                    <div className="info-text">{translation && translation['contacts-voice-portal']}
                        <span> {contacts && contacts.voicePortal}</span>
                        {/* <span>  +374 (60) 743333</span> */}
                    </div>
                    <div className="info-text">{translation && translation['contacts-email']}
                        <span> {contacts && contacts.email}</span>
                    </div>
                    <div
                        className="info-text">{translation && translation['contacts-address']}
                        <span> {contacts && contacts.address}</span>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapDispatchToProps = {
    SendContactsEmail,
    GetContactsWeb,
}
export default connect(mapStateToProps, mapDispatchToProps)(Contact)

// Import packages
import React, {Component} from "react";
import {connect} from "react-redux"
import {Link} from "react-router-dom";

// Import components
import YouTubePlayer from 'react-player/lib/players/YouTube'

// Import styles
import "../../assets/styles/videoCard.scss";

//import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {SetSelectedCategory} from "../redux/actions";
import { createUrl } from "../utils/createUrl";

class VideoCard extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props)
    }

    toggleNewsCategory(id) {
        this.props.SetSelectedCategory(id);
    };

    render() {
        const {card, currentPlayingVideo, toggleCurrentPlayingVideo} = this.props;

        return (
            <div className="video-card">
                <div className="inner-part">
                    {card.videoUrl && <div className="video-img-content">
                        <YouTubePlayer
                            url={card.videoUrl}
                            className="video-youtube"
                            playing={card.id === currentPlayingVideo}
                            width={'100%'}
                            height={'100%'}
                            // preload={true}
                            onPlay={() => toggleCurrentPlayingVideo(card.id)}
                            youtubeConfig={{
                                playerVars: {
                                    showinfo: 0,
                                    rel: 0,
                                    modestbranding: 1,
                                    controls: 1,
                                }
                            }}
                        />
                    </div>}
                </div>
                <div className="categories">
                    {
                        card.categories && card.categories.map((item) => {
                            let title=item.title && item.title.toUpperCase();
                            title = title && title.replace('ԵՒ', 'ԵՎ');
                            return item && !!Object.keys(item).length
                                && <Link to={{
                                    pathname: createUrl('videos'),
                                    state: {categoryId: item.id}
                                }}
                                         key={item.id}>
                                    <div className="chip"
                                         onClick={() => this.toggleNewsCategory(item.id)}>
                                        {title}
                                    </div>
                                </Link>
                        })
                    }
                </div>

                <Link to={{
                    pathname: createUrl('news',card.slug ),
                    state: {newsId: card.id}
                }}>
                    <div className="card-text">{card.title}</div>
                </Link>
            </div>
        )
    }
}

const mapDispatchToProps = {
    SetSelectedCategory
}
export default connect(mapStateToProps, mapDispatchToProps)(VideoCard)

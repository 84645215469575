import {categoriesConsts, authConsts} from "../constants";

const initialState = {
    categoriesList: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case categoriesConsts.GET_CATEGORIES_LIST:
            return {
                categoriesList: action.payload
            };
        case categoriesConsts.CREATE_CATEGORY:
            return {
                categoriesList: [action.payload].concat(state.categoriesList)
            };
        case categoriesConsts.UPDATE_CATEGORY_BY_ID:
            return {
                categoriesList: state.categoriesList.map(category =>
                    category.id === action.payload.id ? action.payload : category
                )
            };
        case categoriesConsts.DELETE_CATEGORY_BY_ID:
            return {
                categoriesList: state.categoriesList.filter(category => category.id !== action.payload)
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
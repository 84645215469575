import {contactConsts,authConsts} from "../constants";

const initialState = {
    contactData: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case contactConsts.GET_CONTACT:
        case contactConsts.UPDATE_CONTACT:
            return {
                ...state,
                contactData: action.payload
            };
        case authConsts.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
// Import packages
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import moment from 'moment';


// Import utils
import {mapStateToProps} from "../../admin/redux/mapStateToProps";
import {SetSelectedCategory} from "../redux/actions";
import {generateMediaUrl} from "../utils/generateMediaUrl";

// Import styles
import "../../assets/styles/newsCard.scss";
import { createUrl } from "../utils/createUrl";

class NewsCard extends Component {
    constructor() {
        super();
        this.toggleNewsCategory = this.toggleNewsCategory.bind(this)
    }

    toggleNewsCategory(id) {
        this.props.SetSelectedCategory(id);
    };

    render() {
        const {news, selectedLanguage} = this.props;
        const momentLocales = moment.locales();
        const momentLocalCode = momentLocales && momentLocales.find(lg => lg.includes(selectedLanguage && selectedLanguage.code));

        const date = news.date && moment(news.date).locale(momentLocalCode ? momentLocalCode : '').format("MMMM DD, YYYY");

        return (
            <div className="news-card-home">
                <div className="inner-part">
                    <div className="news-image">
                        <Link to={{
                            pathname: createUrl('news',news.slug ),
                            state: {newsId: news.id}
                        }}>
                            <img src={generateMediaUrl(news.mediaMain && news.mediaMain.path)}
                                 alt={news.mediaMain ? news.mediaMain.altAttribute : ''}/>
                        </Link>
                    </div>

                    <div className="card-content">
                        <div className="categories">
                            {
                                news.categories && news.categories.map((item) => {
                                    let title=item.title && item.title.toUpperCase();
                                    title = title && title.replace('ԵՒ', 'ԵՎ');
                                    return item && !!Object.keys(item).length
                                        && <Link to={ createUrl('news' )} key={item.id}>
                                            <button
                                                onClick={() => this.toggleNewsCategory(item.id)}>{title}
                                            </button>
                                        </Link>
                                })
                            }
                        </div>

                        <Link to={{
                            pathname: createUrl('news',news.slug ),
                            state: {newsId: news.id}
                        }}>
                            <p className="card-title">{news.title}</p>
                            <p className="card-description"
                               dangerouslySetInnerHTML={{__html: news.description}}/>

                            <div className="card-footer">
                                <img src={require('../../assets/images/ic_calendar.svg')}
                                     className="footer-calendar" alt=""/>
                                <small>{date}</small>

                                <img src={require('../../assets/images/ic_arrow_more.svg')}
                                     className="footer-angel"
                                     alt=""/>

                            </div>
                        </Link>
                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = {
    SetSelectedCategory
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsCard);
